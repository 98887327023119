import { round } from 'lodash';

import { formatByPipSize, getPipSizeNumber } from '@tchtrade/trade-core';

export { formatByPipSize, getPipSizeNumber };

export const defaultPipSize = 0.00001;

export const handleIndicesPipSize = (
  pipSize: number,
  category: string | undefined,
  minOrderSizeIncrement: number
): number => {
  if (category === 'Indices') {
    const increment = minOrderSizeIncrement || 1;
    return round(
      increment > 1 ? +pipSize * increment : +pipSize / increment,
      getPipSizeNumber(pipSize)
    );
  }
  return pipSize;
};

export const getAmountDigitsAfterDot = (value: number): number => {
  if (!value) {
    return 0;
  }
  if (value.toString().includes('.')) {
    const maxDigit = value.toString().split('.');
    const valueMax = maxDigit[maxDigit.length - 1];
    return valueMax.length;
  }
  return 0;
};
