import { TInstrumentEntity } from '@trader/store';

export const getTCInfoUByKey = (indicator: string) => {
  switch (indicator) {
    case 'pivot':
      return {
        color: 'rgb(101, 67, 186)',
        title: 'Pivot',
      };
    case 'resistance1':
      return {
        color: 'rgb(49, 146, 26)',
        title: 'R1',
      };
    case 'resistance2':
      return {
        color: 'rgb(49, 146, 26)',
        title: 'R2',
      };
    case 'resistance3':
      return {
        color: 'rgb(49, 146, 26)',
        title: 'R3',
      };
    case 'support1':
      return {
        color: 'rgb(219, 46, 39)',
        title: 'S1',
      };
    case 'support2':
      return {
        color: 'rgb(219, 46, 39)',
        title: 'S2',
      };
    case 'support3':
    default:
      return {
        color: 'rgb(219, 46, 39)',
        title: 'S3',
      };
  }
};

export const getTradingCentralValues = (
  tradingCentral: TInstrumentEntity['tradingCentral']
) => {
  if (!tradingCentral) {
    return [];
  }

  const indicator = tradingCentral?.feeds[tradingCentral?.feeds?.length - 1];

  if (!indicator) {
    return [];
  }

  const pivot = indicator.paragraphs[0];
  const preference = indicator.paragraphs[1];
  const scenario = indicator.paragraphs[2];
  const comment = indicator.paragraphs[3];
  const supportAndResistant = indicator.paragraphs[4];
  const resistantValues = indicator.paragraphs
    // eslint-disable-next-line no-magic-numbers
    .slice(4)
    .filter(item => /^[\d.]+\s?(\*\*|\*|last)?$/i.test(item));
  const date = new Date(indicator.time).toUTCString();

  return [
    pivot,
    preference,
    scenario,
    comment,
    supportAndResistant,
    ...resistantValues,
    date,
  ];
};

export const getTradingCentralIntraday = (
  tradingCentral: TInstrumentEntity['tradingCentral']
) => {
  const indicator = tradingCentral?.feeds[tradingCentral?.feeds?.length - 1];

  if (!indicator) {
    return null;
  }

  const tcPips = getTradingCentralPips(tradingCentral);
  const intraday = indicator.title;

  return {
    title: intraday.split(':')[0],
    description: intraday.split(':')[1],
    isUpside: !!tcPips?.isRise,
    isDownside: !!tcPips?.isFall,
  };
};

export const getTradingCentralPips = (
  tradingCentral: TInstrumentEntity['tradingCentral']
) => {
  const indicator = tradingCentral?.feeds[tradingCentral?.feeds?.length - 1];

  if (!indicator || !indicator.pips1 || !indicator.pips2) {
    return null;
  }

  return {
    pips1: indicator.pips1,
    pips2: indicator.pips2,
    isRise: indicator.intraday === 1,
    isFall: indicator.intraday === -1,
  };
};
