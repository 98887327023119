import { getRoot, IAnyStateTreeNode, Instance, types } from 'mobx-state-tree';

import {
  calcPositionPl,
  formatMoney,
  getAmountDigitsAfterDot,
  getOrderBasePrice,
  runInAction,
} from '@trader/utils';
import {
  getInstrumentReferenceAsync,
  getRequiredOpenCostAsync,
  prePlaceOrderAsync,
} from './actions';
import {
  TAddProtectionType,
  TPlaceOrderSide,
  TPlaceOrderType,
} from '@trader/types';
import { conversionRate } from '@trader/constants';

import { getRootInstance } from '../configureStore/configureStore';
import { instrumentModel } from '../entities/instruments/index';

type TEditMode = 'Manual' | 'Chart';

interface IPL {
  orderType: TPlaceOrderType;
  side: TPlaceOrderSide;
  takeProfit: string;
  stopLoss: string;
  amount: string;
  price: string;
  openPrice?: string;
}

const commonTradingModel = types
  .model('commonTradingModel', {
    instrument: types.maybeNull(
      types.safeReference(instrumentModel, {
        get(identifier, parent: IAnyStateTreeNode) {
          const root = getRoot(parent) as IAnyStateTreeNode;
          const entity = root.entities.instruments.get(identifier);
          return entity || null;
        },
        set(value) {
          return value.symbol;
        },
      })
    ),
    side: types.frozen<TPlaceOrderSide>(null),
    orderType: types.frozen<TPlaceOrderType>('Market'),
    amount: types.optional(types.string, '0'),
    price: types.optional(types.string, '0'),
    isOrderInPending: false,

    requiredMargin: types.maybeNull(types.number),
    conversionRate: types.optional(types.number, conversionRate),
    isRequiredOpenCostError: false,

    stopLossType: types.frozen<TAddProtectionType>('Rate'),
    takeProfitType: types.frozen<TAddProtectionType>('Rate'),
    isStopLoss: false,
    isTakeProfit: false,
    stopLossPips: types.optional(types.string, '0'),
    stopLoss: types.optional(types.string, '0'),
    takeProfit: types.optional(types.string, '0'),
    takeProfitPips: types.optional(types.string, '0'),

    profit: types.optional(types.string, '--'),
    loss: types.optional(types.string, '--'),

    chartPrice: types.optional(types.string, '0'),
    chartTakeProfit: types.optional(types.string, '0'),
    chartStopLoss: types.optional(types.string, '0'),
    TPEditMode: types.frozen<TEditMode>('Manual'),
    SLEditMode: types.frozen<TEditMode>('Manual'),
    priceEditMode: types.frozen<TEditMode>('Manual'),

    getInstrumentReferenceAsync,
    prePlaceOrderAsync,
    getRequiredOpenCostAsync,
  })
  .views(state => ({
    amountDigitsAfterDot: () =>
      state.instrument
        ? getAmountDigitsAfterDot(state.instrument.minOrderSizeIncrement)
        : 0,
  }))
  .actions(state => ({
    runInAction,
    setFormField: (name: string, value: string | number | boolean) => {
      state[name] = value;
    },

    updateProfit: ({
      orderType,
      side,
      takeProfit,
      amount,
      price,
      openPrice,
    }: IPL) => {
      const root = getRootInstance();

      if (state.instrument) {
        const basePrice =
          orderType !== 'Market'
            ? price
            : getOrderBasePrice(
                side,
                state.instrument.bid,
                state.instrument.ask
              );

        const profit = calcPositionPl({
          side: side,
          currentPrice: +takeProfit,
          openPrice: +(openPrice || basePrice),
          quantity: +amount,
          pipSize: state.instrument.pipSize,
          tickValue: state.instrument.tickValue,
          tickSize: state.instrument.tickSize,
          contractSize: state.instrument.contractSize,
          calcMode: state.instrument.calcMode,
          conversionRate: state.conversionRate,
        });

        state.profit = formatMoney(profit, {
          currencySymbol: root.user.tradingAccount?.currencySymbol || '',
        });
      }
    },
    updateLoss: ({
      orderType,
      side,
      stopLoss,
      amount,
      price,
      openPrice,
    }: IPL) => {
      const root = getRootInstance();

      if (state.instrument) {
        const basePrice =
          orderType !== 'Market'
            ? price
            : getOrderBasePrice(
                side,
                state.instrument.bid,
                state.instrument.ask
              );

        const loss = calcPositionPl({
          side: side,
          currentPrice: +stopLoss,
          openPrice: +(openPrice || basePrice),
          quantity: +amount,
          pipSize: state.instrument.pipSize,
          tickValue: state.instrument.tickValue,
          tickSize: state.instrument.tickSize,
          contractSize: state.instrument.contractSize,
          calcMode: state.instrument.calcMode,
          conversionRate: state.conversionRate,
        });

        state.loss = formatMoney(loss, {
          currencySymbol: root.user.tradingAccount?.currencySymbol || '',
        });
      }
    },
    updatePriceFromChart: (
      value: string,
      key: 'chartTakeProfit' | 'chartStopLoss' | 'chartPrice' = 'chartPrice'
    ) => {
      state[key] = value;
      key === 'chartTakeProfit' && (state.TPEditMode = 'Chart');
      key === 'chartStopLoss' && (state.SLEditMode = 'Chart');
      key === 'chartPrice' && (state.priceEditMode = 'Chart');
    },
    updateEditMode: (
      key: 'TPEditMode' | 'SLEditMode' | 'priceEditMode',
      value: TEditMode
    ) => {
      state[key] = value;
    },
    resetOrderType: () => {
      state.side = null;
      state.orderType = 'Market';
      state.TPEditMode = 'Manual';
      state.SLEditMode = 'Manual';
      state.priceEditMode = 'Manual';
    },
    updateSide: (side: TPlaceOrderSide) => {
      state.side = side;
      state.TPEditMode = 'Manual';
      state.SLEditMode = 'Manual';
      state.priceEditMode = 'Manual';
    },
    updateOrderType: (type: TPlaceOrderType) => {
      state.orderType = type;
      state.TPEditMode = 'Manual';
      state.SLEditMode = 'Manual';
      state.priceEditMode = 'Manual';
    },
    toggleProtectionType: (
      key: 'stopLossType' | 'takeProfitType',
      value: TAddProtectionType
    ) => {
      state[key] = value;
    },
    toggleIsProtection: (
      key: 'isTakeProfit' | 'isStopLoss',
      value: boolean
    ) => {
      state[key] = value;
    },
    clear: () => {
      state.instrument = null;
      state.requiredMargin = null;
      state.conversionRate = conversionRate;
      state.side = null;
      state.orderType = 'Market';
      state.amount = '0';
      state.price = '0';
      state.isOrderInPending = false;
      state.isRequiredOpenCostError = false;
      state.stopLossType = 'Rate';
      state.takeProfitType = 'Rate';
      state.TPEditMode = 'Manual';
      state.SLEditMode = 'Manual';
      state.priceEditMode = 'Manual';
      state.isStopLoss = false;
      state.isTakeProfit = false;
      state.stopLossPips = '0';
      state.stopLoss = '0';
      state.takeProfit = '0';
      state.takeProfitPips = '0';
      state.chartStopLoss = '0';
      state.chartTakeProfit = '0';
      state.profit = '--';
    },
  }));

export const commonTrading = types.optional(commonTradingModel, {});

export type TCommonTradingInstance = Instance<typeof commonTradingModel>;
