import { IInitializeOutput } from './types';
import { indexDBName, stores } from './constants';

export const initialize = (): Promise<IInitializeOutput> =>
  new Promise(resolve => {
    const request = indexedDB.open(indexDBName);

    request.onupgradeneeded = () => {
      stores.forEach(store => {
        if (!request.result.objectStoreNames.contains(store.id)) {
          request.result.createObjectStore(store.id, store.options);
        }
      });
    };

    request.onsuccess = () => {
      resolve({
        readyState: request.readyState,
        version: request.result.version,
      });
    };

    request.onerror = () => {
      resolve({
        readyState: request.readyState,
        version: request.result.version,
      });
    };
  });
