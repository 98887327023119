export const MODAL_TYPES = {
  placeOrder: 'placeOrder',
  addProtection: 'addProtection',
  closePosition: 'closePosition',
  cancelOrder: 'cancelOrder',
  editOrder: 'editOrder',
  createPriceAlert: 'createPriceAlert',
  editPriceAlert: 'createPriceAlert',
  deletePriceAlert: 'deletePriceAlert',
  addFunds: 'addFunds',
  accountStatement: 'accountStatement',
  statistics: 'statistics',
  resultChallenge: 'resultChallenge',
  orderResponse: 'orderResponse',
  changingMuliBandsMultiplier: 'changingMuliBandsMultiplier',
  logOutWithNoActivity: 'logOutWithNoActivity',
  connectMaster: 'connectMaster',
  removeMasterAccount: 'removeMasterAccount',
  removeAllMetrics: 'removeAllMetrics',
  tradingDisabledDueToMarketNews: 'tradingDisabledDueToMarketNews',
  dailyTargetProfitReached: 'dailyTargetProfitReached',
  purchaseChallenge: 'purchaseChallenge',
  muliBandsDisabled: 'muliBandsDisabled',
  switchingToBetaDesign: 'switchingToBetaDesign',
  expiredPassword: 'expiredPassword',
  removeCloseTimeMuliBands: 'removeCloseTimeMuliBands',
} as const;
