import { initialize } from './initialize';
import { remove } from './remove';
import { add } from './add';
import { get } from './get';
import { put } from './put';

import { EStores } from './types';

export { EStores };

let version: number;

export const indexDBService = {
  initialize: async () => {
    const response = await initialize();
    version = response.version;
  },
  add: async <T>(storeName: EStores, data: T, key?: string) =>
    await add<T>({ version, storeName, data, key }),
  put: async <T>(storeName: EStores, data: T, key: string) =>
    await put<T>({ version, storeName, data, key }),
  get: async <T>(storeName: EStores, key: string): Promise<T | undefined> =>
    await get({ version, storeName, key }),
  remove: async (storeName: EStores, key: string) => {
    await remove({ version, storeName, key });
  },
};
