import { Decimal } from 'decimal.js';

import {
  adjustByTickSize as adjustByTickSizeCore,
  OrderSide,
} from '@tchtrade/trade-core';

import { TPlaceOrderSide } from '@trader/types';

export const checkOnTickSize = (value: number | string, tickSize: number) => {
  if (!tickSize) {
    return false;
  }

  const decimal = new Decimal(value);

  return !decimal.mod(tickSize).equals(0);
};

export const adjustByTickSize = (
  value: string,
  pipSize: number,
  tickSize: number,
  side: TPlaceOrderSide,
  type: 'price' | 'tp' | 'sl'
) => {
  return adjustByTickSizeCore(
    value,
    pipSize,
    tickSize,
    side as OrderSide,
    type
  );
};
