import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Trigger = styled(Wrapper)<{ $isSelected: boolean }>`
  ${({ theme, $isSelected }) => css`
    width: 167px;
    height: 43px;
    padding: ${$isSelected ? '11px' : '12px'};
    border: ${$isSelected
      ? `2px solid ${theme.palette.primary.main}`
      : `1px solid ${theme.palette.gray['200']}`};
    justify-content: space-between;
    color: ${theme.palette.primary.dark};
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    user-select: none;

    p {
      font-size: ${theme.typography.default.fontSize};
      font-weight: ${theme.typography.fontWeightMedium};
    }

    &:hover {
      border: 1px solid ${theme.palette.gray['400']};
    }
  `};
`;

export const Label = styled('div')<{ $isSelected: boolean }>`
  ${({ theme, $isSelected }) => css`
    padding: 0 4px;
    position: absolute;
    background: ${theme.palette.common.white};
    color: ${$isSelected ? theme.palette.primary.main : undefined};
    font-size: ${theme.typography.default.fontSize};
    bottom: ${$isSelected ? '31px' : '32px'};
    left: ${$isSelected ? '8px' : '9px'};
  `};
`;

export const Content = styled(Wrapper)`
  ${() => css`
    min-width: 167px;
    padding: 4px 8px;
  `};
`;
