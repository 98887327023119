import React, { useState } from 'react';
import { isEqualWith } from 'lodash';

import { Select } from '@trader/components';
import { IOption } from '@trader/types';
import { useI18next } from '@trader/services';

interface ISelectFilter {
  label?: string;
  options: IOption[];
  defaultOption?: IOption;
  onChange: (values: string[]) => void;
}

export const SelectFilter: React.FC<ISelectFilter> = ({
  label,
  options,
  defaultOption: propDefaultOption,
  onChange,
}) => {
  const { translate } = useI18next();

  const standardOption = { title: translate('ASSETS.ALL'), value: 'all' };
  const defaultOption = propDefaultOption || standardOption;
  const [selectedOptions, setSelectedOptions] = useState([defaultOption]);

  const handleChange = (value: Array<IOption>) => {
    const isDefaultOptionSelected = !!selectedOptions.find(
      v => v.value === defaultOption.value
    );
    const isDefaultOptionSelecting = !!value.find(
      v => v.value === defaultOption.value
    );
    const shouldResetToDefault =
      !value.length ||
      (!isDefaultOptionSelected && isDefaultOptionSelecting) ||
      (value.length === options.length && !isDefaultOptionSelecting);

    const updatedOptions = shouldResetToDefault
      ? [defaultOption]
      : value.filter(v => v.value !== defaultOption.value);

    const hasDifference = !isEqualWith(
      selectedOptions,
      updatedOptions,
      (v1, v2) => v1.value !== v2.value
    );
    if (hasDifference) {
      setSelectedOptions(updatedOptions);
      if (updatedOptions.find(s => s.value === defaultOption.value)) {
        onChange([]);
      } else {
        onChange(
          updatedOptions
            .filter(s => s.value !== defaultOption.value)
            .map(s => s.value)
        );
      }
    }
  };

  return (
    <Select
      options={[defaultOption, ...options]}
      label={label}
      value={selectedOptions}
      onChange={v => handleChange(v.target.value)}
      sx={{
        '& .MuiSelect-select': {
          padding: '10px 32px 10px 14px',
        },
      }}
      multiple
    />
  );
};
