import React, { useCallback } from 'react';
import { useTheme } from '@mui/material';

import { adapterLineStyle, MODAL_TYPES } from '@trader/constants';
import { TOrderMetricEntity, useMst } from '@trader/store';
import { useI18next } from '@trader/services';

import {
  IChartingLibraryWidget,
  IPositionLineAdapter,
} from '../../charting_library';
import { useGetAdapterDiff } from '../muliBands/useGetAdapterDiff';

interface IProtection {
  key: string;
  ref: IPositionLineAdapter | undefined;
}

const protectionLineLength = 18;
const takeProfitMap = new Map<string, IProtection>();
const stopLossMap = new Map<string, IProtection>();

export const useDisplayOrderProtection = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>
) => {
  const store = useMst();
  const theme = useTheme();
  const { translate } = useI18next();

  const { typeColor } = useGetAdapterDiff('Order');

  const createOrderProtection = (id: string) => {
    const order = store.entities.ordersMetrics.get<TOrderMetricEntity>(id);
    if (order?.takeProfit) {
      takeProfitMap.set(id, {
        key: id,
        ref: widget?.current
          ?.activeChart()
          .createPositionLine()
          .setText('')
          .setTooltip(translate('COMMON.LABELS.TAKE_PROFIT'))
          .setProtectTooltip(translate('COMMON.LABELS.TAKE_PROFIT'))
          .setBodyBorderColor(theme.palette.tab.light)
          .setBodyBackgroundColor(theme.palette.tab.light)
          .setCloseButtonIconColor(theme.palette.white.main)
          .setLineColor(typeColor(id, order.side))
          .setLineLength(protectionLineLength)
          .setLineStyle(adapterLineStyle)
          .setQuantity(
            `${translate('COMMON.LABELS.TAKE_PROFIT')}; ID: ${order.orderId}`
          )
          .setQuantityBackgroundColor(theme.palette.green.main)
          .setQuantityBorderColor(theme.palette.green.main)
          .onModify(function () {
            store.ui.modal.open(MODAL_TYPES.editOrder, {
              symbol: order.symbol,
              orderId: order.orderId,
              side: order.side,
            });
          })
          .setPrice(order.takeProfit),
      });
    }

    if (order?.stopLoss) {
      stopLossMap.set(id, {
        key: id,
        ref: widget?.current
          ?.activeChart()
          .createPositionLine()
          .setText('')
          .setTooltip(translate('COMMON.LABELS.STOP_LOSS'))
          .setProtectTooltip(translate('COMMON.LABELS.STOP_LOSS'))
          .setCloseButtonBorderColor(typeColor(id, order.side))
          .setCloseButtonBackgroundColor(typeColor(id, order.side))
          .setBodyBorderColor(theme.palette.tab.light)
          .setBodyBackgroundColor(theme.palette.tab.light)
          .setLineColor(typeColor(id, order.side))
          .setLineLength(protectionLineLength)
          .setLineStyle(adapterLineStyle)
          .setQuantity(
            `${translate('COMMON.LABELS.STOP_LOSS')}; ID: ${order.orderId}`
          )
          .setQuantityBackgroundColor(theme.palette.red.light)
          .setQuantityBorderColor(theme.palette.red.light)
          .onModify(function () {
            store.ui.modal.open(MODAL_TYPES.editOrder, {
              symbol: order.symbol,
              orderId: order.orderId,
              side: order.side,
            });
          })
          .setPrice(order.stopLoss),
      });
    }
  };

  const clearOrderProtection = useCallback(() => {
    Array.from(takeProfitMap.values()).forEach(tp => {
      tp?.ref?.remove();
    });
    Array.from(stopLossMap.values()).forEach(sl => {
      sl?.ref?.remove();
    });
    takeProfitMap.clear();
    stopLossMap.clear();
  }, []);

  const updateOrderProtection = (id: string) => {
    const order = store.entities.ordersMetrics.get<TOrderMetricEntity>(id);

    if (!order) {
      takeProfitMap.get(id)?.ref?.remove();
      stopLossMap.get(id)?.ref?.remove();
      takeProfitMap.delete(id);
      stopLossMap.delete(id);
      return;
    }

    if (!order?.takeProfit) {
      takeProfitMap.get(id)?.ref?.remove();
      takeProfitMap.delete(id);
    } else {
      const ref = takeProfitMap.get(id)?.ref;
      if (ref) {
        ref.setPrice(order.takeProfit);
      } else {
        createOrderProtection(id);
      }
    }

    if (!order?.stopLoss) {
      stopLossMap.get(id)?.ref?.remove();
      stopLossMap.delete(id);
    } else {
      const ref = stopLossMap.get(id)?.ref;
      if (ref) {
        ref.setPrice(order.stopLoss);
      } else {
        createOrderProtection(id);
      }
    }
  };

  return {
    createOrderProtection,
    clearOrderProtection,
    updateOrderProtection,
  };
};
