import React from 'react';
import { observer } from 'mobx-react-lite';

import {
  Form,
  ModalTitle,
  Progress,
  TradingIsClosed,
} from '@trader/components';
import {
  TInstrumentEntity,
  TModalStore,
  TOrderMetricEntity,
  useMst,
} from '@trader/store';
import {
  EConditionType,
  useBusinessLogic,
  useCloseModalForAlreadyDeletedInstance,
  useGetTradingDefaultFormValues,
} from '@trader/hooks';
import { useI18next } from '@trader/services';
import { IInitialTradingFormValues } from '@trader/types';
import { TradingRealTimeUpdatesInitializer } from '@trader/trading';

import {
  IOrderMetricStore,
  TradingModalHeader,
} from '../components/tradingModalHeader';
import { LimitOrder } from '../createOrder/components/LimitOrder';
import { usePlaceOrderFormValidation } from '../createOrder/formValidation';

import * as Styled from './styled';

const EditOrder: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const { applyCondition: applyConditionThird } = useBusinessLogic(
    EConditionType.TradingAllowed
  );
  const { validationSchema } = usePlaceOrderFormValidation('modifyOrder');

  const modal: TModalStore = store.ui.modal;
  const orderId = modal.options.get('orderId');

  const trading = store.trading.getTrading('modifyOrder');
  const instrument = trading.instrument as TInstrumentEntity;

  const metric = store.entities.ordersMetrics.get<TOrderMetricEntity>(orderId);
  const pendingPrice = metric?.stopPrice || metric?.limitPrice;

  const defaultFormValues = useGetTradingDefaultFormValues('modifyOrder', {
    symbol: instrument.symbol,
    side: metric?.side,
    takeProfit: metric?.takeProfit,
    stopLoss: metric?.stopLoss,
    amount: metric?.quantity,
    pendingPrice,
  });

  const handleEditOrder = (data: IInitialTradingFormValues) => {
    metric?.editOrderAsync.run({
      orderId,
      body: {
        side: data.side,
        quantity: Number(data.amount),
        symbol: instrument.symbol,
        type: data.side === 'Sell' ? 'EntryStop' : 'EntryLimit',
        price: Number(data.price),
        stopLoss: data.isStopLoss ? +data.stopLoss : 0,
        takeProfit: data.isTakeProfit ? +data.takeProfit : 0,
      },
    });

    if (store.app.isBetaDesignEnabled()) {
      modal.update({});
    } else {
      // The values will be used in socket handlers
      // to show the notification about order correctly.
      modal.update({ orderId, isOrderEdited: true });
    }
  };

  const handleWatch = (name: string, value: string | number | boolean) => {
    trading.runInAction(() => {
      trading[name] = value;
    });
  };

  if (applyConditionThird().status === 'failed') {
    return (
      <TradingIsClosed
        iconTypeName='warning'
        title={translate('WARNINGS.TRADING_IS_DISABLED')}
        content={translate('WARNINGS.PLEASE_CONTACT_SUPPORT', {
          email: import.meta.env.VITE_SUPPORT_EMAIL,
        })}
        buttonContent={translate('COMMON.LABELS.PROCEED')}
        onClick={applyConditionThird()?.action}
      />
    );
  }

  return (
    <Styled.Root>
      <ModalTitle title={translate('COMMON.LABELS.EDIT_ORDER')} />
      <TradingModalHeader<IOrderMetricStore> type='order' metric={metric} />
      <Form<IInitialTradingFormValues>
        onSubmit={(_reset, data) => {
          handleEditOrder(data);
        }}
        mode='all'
        defaultValues={defaultFormValues}
        validationSchema={validationSchema}
        handleWatch={handleWatch}
        watchingItems={['price']}
      >
        {() => (
          <TradingRealTimeUpdatesInitializer type='modifyOrder'>
            <LimitOrder
              type='modifyOrder'
              isEditingOrder
              buttonTitle={translate('COMMON.LABELS.EDIT_ORDER')}
            />
          </TradingRealTimeUpdatesInitializer>
        )}
      </Form>
    </Styled.Root>
  );
});

export const EditOrderWrapper: React.FC = observer(() => {
  const store = useMst();
  useCloseModalForAlreadyDeletedInstance('orderId');

  const trading = store.trading.getTrading('modifyOrder');
  const instrument = trading.instrument as TInstrumentEntity;

  if (!instrument) {
    return (
      <Styled.Root>
        <Progress />
      </Styled.Root>
    );
  }

  return <EditOrder />;
});
