import { getIconBaseProps } from './utils';

export const timer = {
  ...getIconBaseProps('timer'),
  width: '17px',
  height: '17px',
  viewBox: '0 0 17 17',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <circle
        cx='8.5'
        cy='8.71272'
        r='7.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.66663 5.37939V9.54606H11.8333'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
