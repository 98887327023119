import React from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import { Controller, TradingIsClosed, Wrapper } from '@trader/components';
import { TInstrumentEntity, useMst } from '@trader/store';
import { ICommonOrderType } from '@trader/types';
import { formatByPipSize } from '@trader/utils';
import { useI18next } from '@trader/services';

import { PlaceOrderButton } from '../PlaceOrderButton';
import { PurchaseDetails } from '../PurchaseDetails';
import { CostAndCharges } from '../CostAndCharges';
import { SideButtons } from '../sideButtons';
import { Protection } from '../Protection';

import * as Styled from './styled';

export const MarketOrder: React.FC<ICommonOrderType> = observer(({ type }) => {
  const store = useMst();
  const { translate } = useI18next();
  const { control, register, formState } = useFormContext();

  const trading = store.trading.getTrading(type);
  const instrument = trading.instrument as TInstrumentEntity;
  const freeMargin = store.user.portfolio().freeMargin;
  const isInvestmentAccProduct = store.user.isInvestmentAccProduct();

  if (instrument.tradingAvailability?.isUnavailable) {
    return (
      <TradingIsClosed
        title={translate('COMMON.LABELS.MARKETS_CLOSED')}
        iconTypeName='warning'
        content={`${translate('COMMON.LABELS.MARKETS_ARE_CURRENTLY_CLOSED')} ${
          !isInvestmentAccProduct
            ? translate('COMMON.LABELS.YOU_CAN_PLACE_A_LIMIT_STOP_ORDER')
            : ''
        }`}
      />
    );
  }

  return (
    <React.Fragment>
      {isInvestmentAccProduct && (
        <Styled.DmaHeader>
          <Wrapper>
            <Styled.Info>{translate('COMMON.LABELS.BUY_PRICE')}</Styled.Info>
          </Wrapper>
          <Styled.Price>
            {instrument.currencySymbol}
            {formatByPipSize(Number(instrument.ask), instrument.pipSize)}
          </Styled.Price>
        </Styled.DmaHeader>
      )}
      <React.Fragment>
        <Styled.Root>
          {!isInvestmentAccProduct && <SideButtons type={type} />}
          <Styled.Main $isInvestmentAccProduct={isInvestmentAccProduct}>
            <Controller
              type='tradingInput'
              shouldCheckActivityOfButtons
              customLabel={translate('COMMON.LABELS.AMOUNT')}
              disabled={!instrument.isOpenTrading}
              minValue={instrument.minOrderSizeIncrement}
              maxValue={instrument.maxOrderSize}
              fixDigitAfterDot={trading.amountDigitsAfterDot()}
              step={instrument.minOrderSizeIncrement}
              onCustomChange={() => !trading.side && trading.updateSide('Buy')}
              control={control}
              {...register('amount')}
            />
          </Styled.Main>
          <PurchaseDetails type={type} />
        </Styled.Root>
        <Protection type={type} />
        {isInvestmentAccProduct && (
          <Styled.FreMargin>
            {translate('COMMON.LABELS.FREE_BALANCE')}:{' '}
            {instrument.currencySymbol}
            {freeMargin}
          </Styled.FreMargin>
        )}
        <PlaceOrderButton
          type={type}
          isDisabled={!formState.isValid || trading.isRequiredOpenCostError}
        />
        {import.meta.env.VITE_INSTRUMENT_HAS_COST_AND_CHARGES && (
          <CostAndCharges type={type} />
        )}
      </React.Fragment>
    </React.Fragment>
  );
});
