import React from 'react';
import { observer } from 'mobx-react-lite';

import { EChartLayouts, ETradingView } from '@trader/types';
import { useMst, TInstrumentEntity } from '@trader/store';
import { TradingCentralInfo } from '@trader/components';

import { CandleChart } from '../../../candleChart';
import { AdvanceChart } from '../../../advanceChart';

import * as Styled from './styled';

export const Chart: React.FC = observer(() => {
  const store = useMst();
  const symbol = store.pages.trading.getInstrumentSymbolByLayout();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  const isAdvancedChart = store.app.chartType === ETradingView.Advanced;

  if (!instrument) {
    return <Styled.Child />;
  }

  return (
    <Styled.Box>
      <Styled.Child>
        {isAdvancedChart ? (
          <AdvanceChart
            layoutNumber={EChartLayouts.FirstLayout}
            instrumentSymbol={symbol}
          />
        ) : (
          <CandleChart
            layoutNumber={EChartLayouts.FirstLayout}
            symbol={symbol}
            pipSize={instrument.pipSize}
          />
        )}
      </Styled.Child>
      {!store.app.isBetaDesignEnabled() && <TradingCentralInfo />}
    </Styled.Box>
  );
});
