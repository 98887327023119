import React from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { Controller } from '@trader/components';
import { useDebounce } from '@trader/hooks';
import { MODAL_TYPES } from '@trader/constants';

interface IPositionsCloseTime {
  isCloseTime: boolean;
  closeTime: Date | null;
}

const closeTimeEditing = 3000;

export const PositionsCloseTime: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const { control, getValues, setValue } =
    useFormContext<IPositionsCloseTime>();

  const [closeTime, setCloseTime] = React.useState<Date | null>(null);

  useDebounce(
    () => {
      if (closeTime) {
        store.pages.muliBands.editMuliBandsCloseTimeAsync.run();
        store.notifications.add({
          message: translate('MULI_BANDS.CLOSE_TIME_EDITED'),
          options: {
            variant: 'success',
          },
        });
      }
    },
    [closeTime],
    closeTimeEditing
  );

  const handleClose = (value: boolean) => {
    const onCancel = () => {
      setValue('isCloseTime', true);
    };

    store.pages.muliBands.editMuliBandsCloseTimeAsync.run();

    !value &&
      store.ui.modal.open(MODAL_TYPES.removeCloseTimeMuliBands, {
        shouldHideCloseButton: true,
        cancel: { onCancel },
      });
  };

  const handleEdit = (value: Date) => {
    if (value) {
      setCloseTime(value);
    }
  };

  return (
    <>
      <Controller
        type='switcherField'
        label={translate('MULI_BANDS.CLOSE_TIME')}
        name='isCloseTime'
        control={control}
        onCustomChange={e => {
          handleClose(e.target.checked);
        }}
        shouldHideControlInfo
      />
      {getValues().isCloseTime && (
        <Controller
          type='timePickerField'
          label={translate('MULI_BANDS.CLOSE_TIME')}
          name='closeTime'
          control={control}
          onCustomChange={e => handleEdit(e.target.value)}
        />
      )}
    </>
  );
});
