export enum EStores {
  TRADING_VIEW = 'tradingView',
}

export interface IInitializeOutput {
  readyState: IDBRequestReadyState;
  version: number;
}

export interface IUpdateOutput {
  isSuccess: boolean;
  message?: string;
}

export interface IFullParams<T = ''> {
  version: number;
  storeName: EStores;
  data: T;
  key?: IDBValidKey;
}

export interface IDataFreeParams extends Omit<IFullParams, 'data' | 'key'> {
  key: IDBValidKey;
}
