import React from 'react';
import { observer } from 'mobx-react-lite';

type TObserverableColumn<T> = {
  row: T;
  render: (row: T) => React.ReactNode;
};

export const ObservableTableColumn = observer(
  <T,>({ row, render }: TObserverableColumn<T>) => {
    return <>{render(row)}</>;
  }
);
