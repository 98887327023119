import React from 'react';
import { observer } from 'mobx-react-lite';

import {
  EConditionType,
  useBusinessLogic,
  useGetTradingDefaultFormValues,
} from '@trader/hooks';
import { devLoggerService, useI18next } from '@trader/services';
import { TInstrumentEntity, useMst } from '@trader/store';
import { Form, Progress, TradingIsClosed } from '@trader/components';
import {
  TPlaceOrderType,
  IInitialTradingFormValues,
  TCommonOrderType,
} from '@trader/types';

import { TradingRealTimeUpdatesInitializer } from '../hocs';
import { PlaceOrderHeader, MarketOrder, LimitOrder, Tabs } from '../components';
import { usePlaceOrderFormValidation } from './formValidation';

import * as Styled from './styled';

interface IPlaceOrder {
  modelType?: TCommonOrderType;
}

const Content: React.FC<IPlaceOrder> = observer(
  ({ modelType = 'createOrder' }) => {
    const store = useMst();
    const { translate } = useI18next();

    const trading = store.trading.getTrading(modelType);
    const instrument = trading.instrument as TInstrumentEntity;
    const isInvestmentAccProduct = store.user.isInvestmentAccProduct();

    const defaultFormValues = useGetTradingDefaultFormValues(modelType, {
      symbol: instrument.symbol,
      side: trading.side,
    });

    const { validationSchema } = usePlaceOrderFormValidation(modelType);

    const tabs = [
      {
        value: 'Market',
        label: translate('COMMON.LABELS.MARKET'),
        children: <MarketOrder type={modelType} />,
      },
      {
        value: 'EntryLimit',
        label: translate('COMMON.LABELS.LIMIT_STOP_ORDER'),
        children: <LimitOrder type={modelType} />,
      },
    ];

    const handleWatch = (name: string, value: string | number | boolean) => {
      trading.runInAction(() => {
        trading[name] = value;
      });
    };

    return (
      <Styled.Root $isInvestmentAccProduct={isInvestmentAccProduct}>
        <PlaceOrderHeader symbol={instrument.symbol} />
        <Form<IInitialTradingFormValues>
          onSubmit={async (reset, data) => {
            try {
              await trading.prePlaceOrderAsync.run(data);
              reset({
                ...defaultFormValues,
                orderType: 'Market',
                side: null,
                isStopLoss: false,
                isTakeProfit: false,
              });
            } catch (error) {
              devLoggerService.error('onSubmit_place_order', error);
            }
          }}
          mode='all'
          defaultValues={defaultFormValues}
          validationSchema={validationSchema}
          handleWatch={handleWatch}
          watchingItems={[
            'amount',
            'price',
            'isTakeProfit',
            'takeProfit',
            'isStopLoss',
            'stopLoss',
          ]}
        >
          {({ setValue, reset, getValues, trigger }) => (
            <TradingRealTimeUpdatesInitializer type={modelType}>
              {isInvestmentAccProduct ? (
                <MarketOrder type={modelType} />
              ) : (
                <Tabs
                  tabsPosition='top'
                  tabs={tabs}
                  preselectedTab={trading.orderType}
                  onChange={key => {
                    trading.updateOrderType(key as TPlaceOrderType);
                    setValue('orderType', key as TPlaceOrderType);
                    trigger(['amount']);
                    reset(
                      {
                        side: getValues().side,
                        orderType: key as TPlaceOrderType,
                      },
                      { keepValues: true }
                    );
                  }}
                />
              )}
            </TradingRealTimeUpdatesInitializer>
          )}
        </Form>
      </Styled.Root>
    );
  }
);

export const PlaceOrder: React.FC<IPlaceOrder> = observer(
  ({ modelType = 'createOrder' }) => {
    const store = useMst();
    const { translate } = useI18next();

    const { applyCondition } = useBusinessLogic(
      EConditionType.RegistrationCompleted
    );
    const { applyCondition: applyTradingAllowedCondition } = useBusinessLogic(
      EConditionType.TradingAllowed
    );

    const trading = store.trading.getTrading(modelType);

    const instrument = trading.instrument;
    const isInvestmentAccProduct = store.user.isInvestmentAccProduct();
    const isDailyTargetGoalReached = store.user.profile.blockedDueToDailyTarget;

    if (!instrument) {
      return (
        <Styled.Root
          $isLoading
          $isInvestmentAccProduct={isInvestmentAccProduct}
        >
          <Progress />
        </Styled.Root>
      );
    }

    if (applyCondition().status === 'failed') {
      return (
        <TradingIsClosed
          iconTypeName='completeRegistration'
          title={translate('WARNINGS.COMPLETE_REGISTRATION')}
          content={translate('WARNINGS.HAVENT_COMPLETED_REGISTRATION')}
          buttonContent={translate('COMMON.LABELS.PROCEED')}
          onClick={applyCondition()?.action}
        />
      );
    }

    if (instrument.disabledByMarketNews) {
      return (
        <TradingIsClosed
          iconTypeName='notAllowed'
          title={translate('WARNINGS.TRADING_IS_TEMPORARY_DISABLED')}
          content={translate(
            'WARNINGS.TRADING_IS_TEMPORARY_DISABLED_DESCRIPTION'
          )}
        />
      );
    }

    if (applyTradingAllowedCondition().status === 'failed') {
      return (
        <TradingIsClosed
          iconTypeName='warning'
          title={translate('WARNINGS.TRADING_IS_DISABLED')}
          content={translate('WARNINGS.PLEASE_CONTACT_SUPPORT', {
            email: import.meta.env.VITE_SUPPORT_EMAIL,
          })}
        />
      );
    }

    if (instrument.tradingStatus === 'CLOSE_ONLY') {
      return (
        <TradingIsClosed
          iconTypeName='warning'
          title={translate('WARNINGS.TRADING_LIMITED_TO_CLOSE_ONLY_POSITIONS')}
        />
      );
    }

    if (isDailyTargetGoalReached) {
      return (
        <TradingIsClosed
          iconTypeName='filledCircleCheckmark'
          title={translate('COMMON.LABELS.MAXIMUM_PROFIT_REACHED')}
          content={translate(
            'COMMON.LABELS.MAXIMUM_PROFIT_REACHED_DESCRIPTION'
          )}
        />
      );
    }

    return <Content modelType={modelType} />;
  }
);
