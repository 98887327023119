import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import {
  useExtendItemWidthDependsLng,
  usePriceAlertsCreation,
  usePriceAlertsCurrentPrice,
} from '@trader/hooks';
import {
  IScrollableTableColumn,
  ScrollableTable,
  Wrapper,
  Icon,
  IconButton,
  ObservableTableColumn,
} from '@trader/components';
import { TAlertEntity, useMst } from '@trader/store';
import { MODAL_TYPES } from '@trader/constants';
import { useI18next } from '@trader/services';
import { formatDate, formatTableRowValueByPipSize } from '@trader/utils';

import { PurchaseType, SymbolWithIcon } from '../scrollingList';

import * as Styled from './styled';

export const AlertsList = observer(() => {
  usePriceAlertsCurrentPrice();
  usePriceAlertsCreation();

  const { currentLng, translate } = useI18next();
  const { getExtendedWidth } = useExtendItemWidthDependsLng();

  const store = useMst();
  const alerts = store.entities.alerts.getAll<TAlertEntity>();

  useEffect(() => {
    store.entities.alerts.getAlertsAsync.run();
  }, []);

  const renderActions = item => {
    const alert = item.row;
    const instrument = alert.conditions[0].instrument;

    const openModal = (modalType: keyof typeof MODAL_TYPES) => {
      store.ui.modal.open(modalType, {
        borderRadius: 10,
        symbol: instrument.symbol,
        alertId: alert.id,
      });
    };

    return (
      <Styled.Actions>
        <IconButton
          iconType='edit'
          onClick={event => {
            event.stopPropagation();
            openModal(MODAL_TYPES.editPriceAlert);
          }}
        />
        <IconButton
          iconType='remove'
          onClick={event => {
            event.stopPropagation();
            openModal(MODAL_TYPES.deletePriceAlert);
          }}
        />
      </Styled.Actions>
    );
  };

  const renderAlertPrice = item => {
    const alert = item.row;
    const instrument = alert.conditions[0].instrument;
    const rightExpression = alert?.conditions[0]?.rightExpression;
    const defaultAlertPrice = parseFloat(rightExpression?.parameters?.Number);

    return (
      <Wrapper alignItems='center' justifyContent='center'>
        <Styled.Title>
          {instrument?.currencySymbol}
          {defaultAlertPrice
            ? +formatTableRowValueByPipSize(
                defaultAlertPrice,
                instrument?.pipSize
              )
            : ''}
        </Styled.Title>
        <Icon
          style={{ margin: '0 0 1px 4px' }}
          iconType={
            alert?.conditions[0]?.compareType === 'BiggerThen'
              ? 'abovePrice'
              : 'belowPrice'
          }
        />
      </Wrapper>
    );
  };

  const renderSide = item => {
    const alert = item.row;
    const indicatorType = alert.conditions[0]?.leftExpression?.indicatorType;
    const side =
      indicatorType === 'Ask' ? 'Sell' : indicatorType === 'Bid' ? 'Buy' : '';

    if (!side) {
      return;
    }

    return <PurchaseType value={side} />;
  };

  const renderCurrentPrice = item => {
    const alert = item.row;
    const instrument = alert.conditions[0].instrument;

    return (
      <Styled.Title>
        {instrument?.currencySymbol}
        {formatTableRowValueByPipSize(
          instrument?.currentPrice,
          instrument?.pipSize
        )}
      </Styled.Title>
    );
  };

  const columns: IScrollableTableColumn<TAlertEntity>[] = useMemo(
    () => [
      {
        id: 'symbol',
        header: translate('COMMON.LABELS.SYMBOL'),
        minWidth: 115,
        cellStyle: { paddingLeft: '15px' },
        headerStyle: { textAlign: 'left', paddingLeft: '20px' },
        render: row => {
          const instrument = row.conditions[0].instrument;
          return (
            <SymbolWithIcon
              symbol={instrument?.symbol}
              iconUrl={instrument?.iconUrl}
            />
          );
        },
      },
      {
        id: 'side',
        header: translate('COMMON.LABELS.TYPE'),
        minWidth: getExtendedWidth('60', '2', ['nl']),
        render: row => renderSide({ row }),
      },
      {
        id: 'name',
        header: translate('COMMON.LABELS.CREATION_DATE'),
        minWidth: 190,
        render: row =>
          formatDate(new Date(row.name), 'Mm dd, yyyy hh:mm:ss', currentLng),
      },
      {
        id: 'endTime',
        header: translate('COMMON.LABELS.EXPIRATION_DATE'),
        minWidth: 190,
        render: row =>
          row.endTime
            ? formatDate(
                new Date(row.endTime as string),
                'Mm dd, yyyy hh:mm:ss',
                currentLng
              )
            : '',
      },
      {
        id: 'alertPrice',
        header: translate('COMMON.LABELS.ALERT_WHEN'),
        minWidth: 110,
        render: row => (
          <ObservableTableColumn
            row={row}
            render={r => renderAlertPrice({ row: r })}
          />
        ),
      },
      {
        id: 'currentPrice',
        header: translate('COMMON.LABELS.CURRENT_PRICE'),
        minWidth: 110,
        render: row => (
          <ObservableTableColumn
            row={row}
            render={r => renderCurrentPrice({ row: r })}
          />
        ),
      },
      {
        id: 'actions',
        header: '',
        minWidth: getExtendedWidth('80', '1.35', ['sk']),
        cellStyle: { paddingLeft: '15px' },
        headerStyle: { textAlign: 'right', paddingRight: '20px' },
        render: row => renderActions({ row }),
      },
    ],
    []
  );

  return (
    <Styled.Root>
      <ScrollableTable<TAlertEntity> data={alerts} columns={columns} />
    </Styled.Root>
  );
});
