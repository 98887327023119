import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { Button, Tooltip } from '@trader/components';

import * as Styled from '../../styled';

export const ToggleLineAdaptersOnChart: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  return (
    <Styled.ToggleLineAdaptersOnChart>
      <Tooltip
        title={
          <Styled.TooltipTitle>
            {translate('TRADING.SHOW_HIDE_LINES')}
          </Styled.TooltipTitle>
        }
      >
        <Button
          variant='outlined'
          size='small'
          onClick={store.ui.tradingView.toggleShouldDisplayAllLineAdapters}
        >
          {store.ui.tradingView.shouldDisplayAllLineAdapters
            ? translate('COMMON.LABELS.HIDE')
            : translate('COMMON.LABELS.SHOW')}
        </Button>
      </Tooltip>
    </Styled.ToggleLineAdaptersOnChart>
  );
});
