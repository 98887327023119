import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { LOCAL_STORAGE_KEYS } from '@trader/constants';
import { devLoggerService, localStorageService } from '@trader/services';

export interface IInAppMessage {
  title?: string;
  body?: string;
}

class InAppMessagingService {
  private readonly _vapidKey: string;

  constructor(vapidKey: string) {
    this._vapidKey = vapidKey;
  }

  async registerMessagingServiceWorker() {
    if ('serviceWorker' in navigator) {
      const existingRegistration =
        await navigator.serviceWorker.getRegistration(
          '/firebase-messaging-sw.js'
        );

      if (!existingRegistration) {
        try {
          await navigator.serviceWorker.register('/firebase-messaging-sw.js');
          devLoggerService.log('Messaging service worker registered.');
        } catch (e) {
          devLoggerService.error(
            'Messaging service worker registration failed:',
            e
          );
        }
      }
    }
  }

  async requestBrowserPermission() {
    try {
      await Notification.requestPermission();
    } catch (error) {
      devLoggerService.error('Error getting permission:', error);
    }
  }

  async proceedMessagingToken() {
    const token = await getToken(getMessaging(), { vapidKey: this._vapidKey });
    localStorageService.set(
      LOCAL_STORAGE_KEYS.tokens.firebaseMessagingToken,
      token
    );
  }

  isPermissionGranted() {
    // safari iphone doesn't support Notification api
    const isSupported =
      'Notification' in window &&
      'serviceWorker' in navigator &&
      'PushManager' in window;

    return isSupported ? Notification.permission === 'granted' : false;
  }

  handleMessaging(onReceived: (message: IInAppMessage) => void) {
    onMessage(getMessaging(), payload =>
      onReceived({
        title: payload.data?.['pinpoint.notification.title'],
        body: payload.data?.['pinpoint.notification.body'],
      })
    );
  }
}

export const inAppMessagingService = new InAppMessagingService(
  import.meta.env.VITE_FCM_VAPID_KEY
);
