import { FC, useState } from 'react';
import { View, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import { imageGenerationService, useI18next } from '@trader/services';
import { useMst } from '@trader/store';

import { IAccountStatementHtmlReport } from '../htmlReport';
import { PdfReport } from '../pdfReport';
import { recursivelyConvertJsonToPdfComponents } from '../../utils';
import * as Styled from './styled';

export const DownloadReportButton: FC<IAccountStatementHtmlReport> = ({
  ...pdfReportProps
}) => {
  const store = useMst();
  const { translate } = useI18next();
  const [isGenerating, setIsGenerating] = useState(false);

  const handleDownloadButtonClick = async () => {
    try {
      setIsGenerating(true);

      const chartEl = document.querySelector('.recharts-responsive-container');
      const chart = chartEl
        ? await imageGenerationService.fromHtmlToDataUrl({
            element: chartEl as HTMLElement,
          })
        : null;

      const component = (
        <PdfReport {...pdfReportProps} balanceChart={chart} logo={<Logo />} />
      );
      const blob = await pdf(component).toBlob();
      const filename = 'Account-Statement.pdf';

      saveAs(blob, filename);
    } catch (e) {
      store.notifications.add({
        message: translate('WARNINGS.FILE_GENERATION_FAILED'),
        options: {
          variant: 'error',
        },
      });
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Styled.DownloadButton
      disabled={isGenerating}
      onClick={handleDownloadButtonClick}
    >
      {isGenerating
        ? translate('COMMON.LABELS.GENERATING_DOCUMENT')
        : translate('COMMON.LABELS.DOWNLOAD_PDF')}
    </Styled.DownloadButton>
  );
};

const Logo = () => {
  const logo = document.getElementById('logo');

  if (logo) {
    const logoHtml = logo.innerHTML;
    const sv = recursivelyConvertJsonToPdfComponents(logoHtml, false);

    return sv;
  }

  return <View></View>;
};
