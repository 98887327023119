import { calculateQuotePrice } from '@tchtrade/trade-core';

export const getSpreadDifference = (
  spreadDiff: number,
  a: number,
  b: number,
  spreadDiffBalance: number,
  pipSize: number
) => {
  const prices = calculateQuotePrice(
    { a, b, s: '', v: 0, t: 0, p: 0 },
    { spreadDiff, spreadDiffBalance, pipSize }
  );
  return {
    ask: prices.a,
    bid: prices.b,
  };
};
