import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import { emptyFn, maxMultiplier, minMultiplier } from '@trader/constants';
import { TInstrumentEntity, TMuliBandsStore, useMst } from '@trader/store';
import { Controller, Form } from '@trader/components';
import { getAmountDigitsAfterDot, getPipSizeNumber } from '@trader/utils';
import { useI18next } from '@trader/services';

import { StopLoss } from '../../../stopLoss';
import { useOrderFormValidation } from './formValidation';

import * as Styled from './styled';

const Content: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const muliBands: TMuliBandsStore = store.pages.muliBands;

  const instrument =
    store.entities.instruments.get<TInstrumentEntity>(muliBands.symbol) ||
    store.entities.instruments.getAll<TInstrumentEntity>()[0];

  const { control, setValue } = useFormContext<IOrderInitialValues>();

  useEffect(() => {
    setValue('orderAmount', muliBands.orderAmount);
    setValue('topMultiplier', muliBands.topMultiplier);
    setValue('bottomMultiplier', muliBands.bottomMultiplier);
    setValue('isSl', muliBands.isSl);
    setValue('sl', muliBands.sl);
  }, [
    muliBands.id,
    instrument.minOrderSize,
    muliBands.topMultiplier,
    muliBands.bottomMultiplier,
  ]);

  return (
    <Styled.Root>
      <Controller
        type='tradingInput'
        shouldCheckActivityOfButtons
        customLabel={translate('COMMON.LABELS.AMOUNT')}
        disabled={!muliBands.id}
        minValue={instrument.minOrderSize}
        maxValue={instrument.maxOrderSize}
        step={instrument.minOrderSizeIncrement}
        fixDigitAfterDot={getAmountDigitsAfterDot(
          instrument.minOrderSizeIncrement
        )}
        control={control}
        name='orderAmount'
      />
      <Controller
        type='tradingInput'
        shouldCheckActivityOfButtons
        customLabel={translate('MULI_BANDS.TOP_MULTIPLIER')}
        disabled={!muliBands.id}
        minValue={minMultiplier}
        maxValue={maxMultiplier}
        step={minMultiplier}
        fixDigitAfterDot={getPipSizeNumber(minMultiplier)}
        onCustomChange={() => muliBands.toggleHasMultiplierChanged(true)}
        control={control}
        name='topMultiplier'
      />
      <Controller
        type='tradingInput'
        shouldCheckActivityOfButtons
        customLabel={translate('MULI_BANDS.BOTTOM_MULTIPLIER')}
        disabled={!muliBands.id}
        minValue={minMultiplier}
        maxValue={maxMultiplier}
        step={minMultiplier}
        fixDigitAfterDot={getPipSizeNumber(minMultiplier)}
        onCustomChange={() => muliBands.toggleHasMultiplierChanged(true)}
        control={control}
        name='bottomMultiplier'
      />
      <Styled.Sl>
        <StopLoss />
      </Styled.Sl>
    </Styled.Root>
  );
});

interface IOrderInitialValues {
  orderAmount: string;
  topMultiplier: string;
  bottomMultiplier: string;
  sl: string;
  isSl: boolean;
}

export const Order: React.FC = observer(() => {
  const store = useMst();
  const validationSchema = useOrderFormValidation();

  const muliBands = store.pages.muliBands;

  const defaultFormValues: IOrderInitialValues = {
    orderAmount: muliBands.orderAmount,
    topMultiplier: muliBands.topMultiplier,
    bottomMultiplier: muliBands.bottomMultiplier,
    isSl: muliBands.isSl,
    sl: muliBands.sl,
  };

  const handleWatch = (name: string, value: string | number | boolean) => {
    muliBands.runInAction(() => {
      muliBands[name] = value;
    });
  };

  return (
    <Form<IOrderInitialValues>
      onSubmit={emptyFn}
      mode='all'
      defaultValues={defaultFormValues}
      validationSchema={validationSchema}
      handleWatch={handleWatch}
    >
      {() => <Content />}
    </Form>
  );
});
