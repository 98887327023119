import { getIconBaseProps } from './utils';

export const finexoLogo = {
  ...getIconBaseProps('finexoLogo'),
  width: '100%',
  height: '100%',
  viewBox: '0 0 140 37',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M0 1.97131C0 1.22466 0.605279 0.619385 1.35193 0.619385H34.4742C35.2208 0.619385 35.8261 1.22466 35.8261 1.97131V34.8232C35.8261 35.5698 35.2208 36.1751 34.4742 36.1751H1.35193C0.605278 36.1751 0 35.5698 0 34.8232V1.97131Z'
        fill='url(#paint0_linear_17_13935)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.7843 9.53118L25.2977 9.61091L27.165 8.40691C28.9029 7.18989 29.181 6.97349 30.4857 5.5352L31.3231 4.50854L31.3199 4.92995C31.3183 5.25047 31.2924 5.57099 31.2455 5.88827C31.1679 6.41217 31.0289 6.92306 30.8333 7.41442L30.6166 7.95622L30.4242 8.05059C29.7113 8.4004 28.9724 8.69652 28.2158 8.93569L28.0622 8.9845C27.3169 9.22205 26.5555 9.40428 25.7843 9.52956V9.53118ZM19.6586 19.6871C18.9408 19.7994 18.2132 19.8189 17.4938 19.7457C17.7686 20.1557 18.0014 20.5966 18.1857 21.0636C18.4137 21.6379 18.5641 22.2415 18.632 22.8566L18.6788 23.278C18.8842 25.1067 18.5899 26.955 17.8268 28.6276L16.2101 32.1729L16 30.5589C15.6427 27.8109 14.5433 25.2141 12.8215 23.0518C11.3535 21.2084 10.335 19.0461 9.84676 16.7357L9.74168 16.2411C8.44507 15.6488 7.05147 15.2974 5.62553 15.2079L4.55042 15.1396L4.49707 15.1558L4.5197 15.1379H4.50192C4.50192 15.1379 4.52132 15.1314 4.53749 15.1249L6.40802 13.5955C7.4702 12.7267 8.6504 12.0157 9.91143 11.482C10.4498 11.2542 11.0076 11.0671 11.5734 10.9255C12.904 10.5904 14.2798 10.4456 15.6491 10.4976C15.6491 10.4976 18.9585 10.6571 19.8962 10.6799C21.0425 10.7075 22.1192 10.675 22.9421 10.6343C24.0285 10.5822 25.5741 10.3073 26.7672 10.086L27.7648 9.85334L29.1778 9.42055L30.2351 9.01216L29.396 10.252C28.0929 12.1751 26.2612 13.672 24.1223 14.562C22.9437 15.0517 21.694 15.3478 20.4217 15.4373L20.336 15.4438C18.8114 15.5512 17.2788 15.3738 15.8173 14.9215L14.5094 14.5164C14.2345 14.4318 13.9564 14.3553 13.6767 14.287L14.5514 14.7686C15.7979 15.4552 17.143 15.9384 18.5398 16.2036L18.598 16.215C19.4338 16.3728 20.2826 16.4477 21.133 16.4379C22.9906 16.4168 24.8207 15.9921 26.4989 15.1932L27.7858 14.5815L26.6977 16.0816C25.7972 17.1636 24.5184 18.0454 23.2574 18.6653C22.3536 19.1111 21.3868 19.417 20.3926 19.5732L19.6537 19.6887L19.6586 19.6871Z'
        fill='white'
      />
      <path
        d='M121.721 18.4368C121.721 17.2052 121.956 16.0385 122.426 14.9366C122.896 13.8346 123.544 12.8623 124.37 12.0197C125.213 11.1608 126.185 10.4883 127.287 10.0022C128.389 9.51602 129.572 9.27295 130.836 9.27295C132.084 9.27295 133.259 9.51602 134.361 10.0022C135.462 10.4883 136.435 11.1608 137.277 12.0197C138.136 12.8623 138.801 13.8346 139.271 14.9366C139.757 16.0385 140 17.2052 140 18.4368C140 19.7008 139.757 20.8838 139.271 21.9857C138.801 23.0876 138.136 24.0599 137.277 24.9026C136.435 25.729 135.462 26.3772 134.361 26.8472C133.259 27.3171 132.084 27.5521 130.836 27.5521C129.572 27.5521 128.389 27.3171 127.287 26.8472C126.185 26.3772 125.213 25.729 124.37 24.9026C123.544 24.0599 122.896 23.0876 122.426 21.9857C121.956 20.8838 121.721 19.7008 121.721 18.4368ZM125.367 18.4368C125.367 19.2309 125.505 19.9763 125.78 20.6731C126.072 21.3537 126.469 21.9614 126.971 22.4961C127.49 23.0147 128.081 23.4198 128.746 23.7115C129.426 24.0032 130.163 24.149 130.957 24.149C131.719 24.149 132.424 24.0032 133.072 23.7115C133.737 23.4198 134.312 23.0147 134.798 22.4961C135.284 21.9614 135.665 21.3537 135.94 20.6731C136.216 19.9763 136.354 19.2309 136.354 18.4368C136.354 17.6266 136.208 16.873 135.916 16.1762C135.641 15.4794 135.252 14.8717 134.749 14.3532C134.263 13.8184 133.688 13.4052 133.024 13.1135C132.359 12.8218 131.638 12.676 130.86 12.676C130.082 12.676 129.361 12.8218 128.697 13.1135C128.033 13.4052 127.449 13.8184 126.947 14.3532C126.444 14.8717 126.056 15.4794 125.78 16.1762C125.505 16.873 125.367 17.6266 125.367 18.4368Z'
        fill='currentColor'
      />
      <path
        d='M104.598 9.46729H109.094L120.762 27.3575H116.216L104.598 9.46729ZM104.354 27.3575L110.723 17.3429L112.376 20.8917L108.73 27.3575H104.354ZM112.619 16.0303L116.314 9.46729H120.592L114.442 19.0687L112.619 16.0303Z'
        fill='currentColor'
      />
      <path
        d='M89.8667 9.46729H102.069V12.8703H93.3913V16.6866H101.072V20.0896H93.3913V23.9545H102.409V27.3575H89.8667V9.46729Z'
        fill='currentColor'
      />
      <path
        d='M85.1621 28.0868L71.5986 15.8359L72.6438 16.4193L72.7167 27.3576H69.1436V8.76245H69.2894L82.5612 20.9647L81.7834 20.6244L81.7104 9.46736H85.2593V28.0868H85.1621Z'
        fill='currentColor'
      />
      <path
        d='M61.002 9.46729H64.5265V27.3575H61.002V9.46729Z'
        fill='currentColor'
      />
      <path
        d='M46.4272 9.46729H57.6086V12.8703H49.9518V16.9296H56.5877V20.3327H49.9518V27.3575H46.4272V9.46729Z'
        fill='currentColor'
      />
      <defs>
        <linearGradient
          id='paint0_linear_17_13935'
          x1='17.4132'
          y1='10.4917'
          x2='13.0152'
          y2='32.4626'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FE8C00' />
          <stop offset='1' stopColor='#F83600' />
        </linearGradient>
      </defs>
    </>
  ),
};
