import { getIconBaseProps } from './utils';

export const funded24LoginDarkLogo = {
  ...getIconBaseProps('funded24LoginDarkLogo'),
  width: '100%',
  height: '100%',
  viewBox: '0 0 3600 620',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <g clip-path='url(#clip0_1_3)'>
        <path
          d='M553.01 0H68.08C30.4805 0 0 30.4805 0 68.08V553.01C0 590.61 30.4805 621.09 68.08 621.09H553.01C590.61 621.09 621.09 590.61 621.09 553.01V68.08C621.09 30.4805 590.61 0 553.01 0Z'
          fill='url(#paint0_linear_1_3)'
        />
        <path
          d='M98.24 163.74C77.86 176.33 62.05 195.11 53.12 217.33L116.38 254.45C126.84 230.61 142.96 218.71 164.74 218.74C182.72 217.26 198.51 230.63 199.99 248.62C200.12 250.25 200.14 251.88 200.03 253.51C200.03 269.44 188.91 288.85 166.67 311.75L56.41 426.4V480.98H280.07V408.57H169.5L224.19 353.52C258.24 319.33 275.3 284.89 275.37 250.21C275.37 218.28 264.5 192.82 242.75 173.83C220.95 154.9 192.88 144.78 164.02 145.44C140.82 145.25 118.03 151.6 98.25 163.74'
          fill='white'
        />
        <path
          d='M406.01 151.98L301.24 358.75V428.28H438.74V358.75H382.01L486.84 151.98H406.01Z'
          fill='white'
        />
        <path d='M559.66 358.75H538.28V428.28H559.66V358.75Z' fill='white' />
        <path
          d='M496.35 259.78H481.66V288.28H451.13V358.75V428.28V480.93H481.66V509.42H496.35V480.93H526.88V288.28H496.35V259.78Z'
          fill='white'
        />
        <path
          d='M840.23 190.18C798.21 186.27 777.21 203.89 777.21 243.05V248.39H840.23V303.93H777.21V515.42H719.53V303.93H678.94V248.39H719.53V243.05C719.53 206.38 729.76 178.52 750.24 159.47C770.71 140.43 800.71 132.15 840.23 134.64V190.18Z'
          fill='black'
        />
        <path
          d='M1053.86 248.39H1111.54V515.42H1053.86V481.24C1036.41 508.66 1008.82 522.36 971.08 522.36C940.46 522.36 915.71 512.57 896.85 492.99C877.97 473.41 868.54 446.53 868.54 412.35V248.39H926.22V406.47C926.22 426.41 931.56 441.72 942.24 452.4C952.92 463.08 967.52 468.42 986.03 468.42C1006.32 468.42 1022.7 462.1 1035.16 449.46C1047.62 436.83 1053.85 417.51 1053.85 391.51V248.38L1053.86 248.39Z'
          fill='black'
        />
        <path
          d='M1319.28 241.45C1349.9 241.45 1374.64 251.25 1393.51 270.82C1412.38 290.41 1421.82 317.28 1421.82 351.46V515.42H1364.14V357.34C1364.14 337.4 1358.8 322.09 1348.12 311.41C1337.44 300.73 1322.84 295.39 1304.33 295.39C1284.04 295.39 1267.66 301.72 1255.2 314.35C1242.73 326.99 1236.51 346.31 1236.51 372.29V515.42H1178.83V248.39H1236.51V282.57C1253.95 255.16 1281.55 241.45 1319.29 241.45H1319.28Z'
          fill='black'
        />
        <path
          d='M1695.26 141.58H1752.94V515.42H1695.26V476.97C1673.54 507.24 1642.39 522.37 1601.8 522.37C1565.13 522.37 1533.79 508.75 1507.81 481.51C1481.82 454.27 1468.82 421.08 1468.82 381.91C1468.82 342.74 1481.81 309.1 1507.81 282.04C1533.8 254.99 1565.13 241.45 1601.8 241.45C1642.39 241.45 1673.54 256.4 1695.26 286.31V141.58ZM1550.53 443.06C1566.55 459.27 1586.66 467.36 1610.88 467.36C1635.1 467.36 1655.21 459.27 1671.23 443.06C1687.25 426.86 1695.26 406.48 1695.26 381.91C1695.26 357.34 1687.25 336.97 1671.23 320.76C1655.21 304.56 1635.09 296.46 1610.88 296.46C1586.67 296.46 1566.55 304.56 1550.53 320.76C1534.51 336.97 1526.5 357.34 1526.5 381.91C1526.5 406.48 1534.51 426.86 1550.53 443.06Z'
          fill='black'
        />
        <path
          d='M1865.62 406.48C1869.89 426.77 1879.68 442.35 1894.99 453.21C1910.29 464.08 1928.99 469.5 1951.07 469.5C1981.69 469.5 2004.65 458.47 2019.96 436.39L2067.49 464.16C2041.14 502.97 2002.15 522.37 1950.53 522.37C1907.09 522.37 1872.02 509.11 1845.32 482.58C1818.62 456.06 1805.27 422.5 1805.27 381.91C1805.27 341.32 1818.44 308.66 1844.79 281.77C1871.13 254.89 1904.96 241.45 1946.26 241.45C1985.42 241.45 2017.56 255.16 2042.66 282.57C2067.76 309.99 2080.31 343.28 2080.31 382.44C2080.31 388.5 2079.59 396.51 2078.17 406.47H1865.61L1865.62 406.48ZM1865.09 359.48H2022.1C2018.18 337.77 2009.19 321.39 1995.13 310.35C1981.06 299.32 1964.6 293.79 1945.73 293.79C1924.37 293.79 1906.56 299.66 1892.32 311.41C1878.08 323.16 1869 339.18 1865.08 359.48H1865.09Z'
          fill='black'
        />
        <path
          d='M2340.93 141.58H2398.61V515.42H2340.93V476.97C2319.21 507.24 2288.06 522.37 2247.47 522.37C2210.8 522.37 2179.46 508.75 2153.48 481.51C2127.49 454.27 2114.49 421.08 2114.49 381.91C2114.49 342.74 2127.48 309.1 2153.48 282.04C2179.47 254.99 2210.8 241.45 2247.47 241.45C2288.06 241.45 2319.21 256.4 2340.93 286.31V141.58ZM2196.2 443.06C2212.22 459.27 2232.33 467.36 2256.55 467.36C2280.77 467.36 2300.88 459.27 2316.9 443.06C2332.92 426.86 2340.93 406.48 2340.93 381.91C2340.93 357.34 2332.92 336.97 2316.9 320.76C2300.88 304.56 2280.76 296.46 2256.55 296.46C2232.34 296.46 2212.22 304.56 2196.2 320.76C2180.18 336.97 2172.17 357.34 2172.17 381.91C2172.17 406.48 2180.18 426.86 2196.2 443.06Z'
          fill='black'
        />
        <path
          d='M2502.41 515.18C2492.34 525.3 2475.98 525.35 2465.86 515.29C2460.95 510.41 2458.21 503.77 2458.24 496.86C2458.03 482.83 2469.23 471.28 2483.26 471.07C2483.54 471.07 2483.81 471.07 2484.09 471.07C2498.4 471.23 2509.86 482.96 2509.7 497.27C2509.62 503.88 2507.02 510.22 2502.42 514.98'
          fill='#1B1C1C'
        />
        <path
          d='M2674.51 523.24C2631.26 523.24 2595.32 508.9 2566.67 480.21C2538.22 452.11 2522.65 413.5 2523.64 373.52C2522.62 333.54 2538.19 294.92 2566.67 266.83C2595.32 238.11 2631.26 223.77 2674.51 223.8C2701.07 223.45 2727.24 230.28 2750.24 243.56C2771.96 255.79 2789.25 274.58 2799.63 297.23L2776.14 310.95C2767.72 292.61 2753.77 277.37 2736.24 267.38C2717.54 256.52 2696.23 250.97 2674.61 251.3C2642.12 250.56 2610.77 263.34 2588.06 286.59C2564.94 309.53 2552.28 340.96 2553.05 373.52C2552.27 406.06 2564.94 437.49 2588.06 460.39C2610.77 483.67 2642.14 496.46 2674.66 495.68C2696.27 496.03 2717.56 490.47 2736.23 479.6C2753.97 469.34 2768.41 454.22 2777.83 436.03L2802.09 450.35C2790.52 472.66 2772.69 491.11 2750.78 503.42C2727.68 516.72 2701.43 523.56 2674.77 523.23'
          fill='#59595C'
        />
        <path
          d='M2970.47 523.24C2930.4 524.04 2891.74 508.39 2863.51 479.94C2834.7 452.13 2818.78 413.57 2819.61 373.53C2818.77 333.49 2834.69 294.92 2863.51 267.12C2922.91 209.38 3017.48 209.38 3076.88 267.12C3105.71 294.92 3121.62 333.49 3120.78 373.53C3121.6 413.57 3105.69 452.13 3076.88 479.94C3048.83 508.32 3010.37 523.97 2970.47 523.24ZM2884.42 460.13C2932.09 507.55 3009.12 507.55 3056.8 460.13C3079.8 437.25 3092.45 405.97 3091.81 373.53C3092.45 341.08 3079.8 309.78 3056.8 286.88C3009.13 239.46 2932.1 239.46 2884.42 286.88C2861.28 309.71 2848.52 341.03 2849.13 373.53C2848.52 406.01 2861.28 437.32 2884.42 460.13Z'
          fill='#59595C'
        />
        <path
          d='M3472.95 223.82C3504.34 223.82 3529.59 233.57 3548.68 253.07C3567.78 272.57 3577.35 299.9 3577.38 335.06V516.93H3548.46V335.06C3548.46 308.33 3541.49 287.64 3527.55 273.16C3513.03 258.34 3492.91 250.36 3472.18 251.21C3448.55 250.38 3425.77 260.04 3409.95 277.61C3394.07 295.24 3386.13 321.64 3386.13 356.8V516.94H3357.48V335.07C3357.48 308.34 3350.84 287.65 3337.67 273.17C3323.9 258.31 3304.29 250.28 3284.05 251.22C3260.09 250.68 3237.03 260.38 3220.66 277.89C3203.65 295.67 3195.14 321.98 3195.14 356.81V516.95H3166.44V230.41H3195.14V279.8C3215.01 242.7 3245.78 224.15 3287.45 224.15C3306.84 223.75 3325.91 229.03 3342.33 239.35C3358.16 249.7 3370.23 264.89 3376.74 282.65C3385.57 264.33 3399.65 249.05 3417.19 238.75C3434.01 229.08 3453.1 224.04 3472.51 224.15'
          fill='#59595C'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_1_3'
          x1='579.63'
          y1='620.09'
          x2='41.47'
          y2='1.01003'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.59' stop-color='#190787' />
          <stop offset='0.79' stop-color='#14056D' />
          <stop offset='1' stop-color='#090333' />
        </linearGradient>
        <clipPath id='clip0_1_3'>
          <rect width='3577.38' height='622.28' fill='white' />
        </clipPath>
      </defs>
    </>
  ),
};
