export type TPlaceOrderType = 'Market' | 'EntryLimit' | 'EntryStop';
export type TPlaceOrderSide = 'Buy' | 'Sell' | null;
export type TProtectionType = 'TakeProfit' | 'StopLoss';
export type TAddProtectionType = 'Rate' | 'Pips';

export type TOrderVariation = 'minus' | 'plus';

export interface IInitialTradingFormValues {
  amount: string;
  price: string;
  ask: number;
  bid: number;
  side: TPlaceOrderSide;
  isTakeProfit: boolean;
  takeProfitType: TAddProtectionType;
  takeProfit: string;
  takeProfitPips: string;
  isStopLoss: boolean;
  stopLossPips: string;
  stopLossType: TAddProtectionType;
  stopLoss: string;
  orderType: TPlaceOrderType;
}

export type TCommonOrderType =
  | 'createOrder'
  | 'modifyOrder'
  | 'createStrategyBand';

export interface ICommonOrderType {
  type: TCommonOrderType;
}

export enum ETradeMode {
  Disabled = 'TradeDisabled',
  BuyOnly = 'LongOnly',
  SellOnly = 'ShortOnly',
  CloseOnly = 'CloseOnly',
  Full = 'Full',
}
