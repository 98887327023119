import styled, { css } from 'styled-components';
import { Wrapper } from '../wrapper';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .icon.edit path {
      stroke: ${theme.palette.gray.dark} !important;
    }

    .icon.remove path {
      stroke: ${theme.palette.red.light} !important;
    }

    ::-webkit-scrollbar {
      border-radius: 5px;
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.gray.dark};
      border-radius: 5px;
    }
  `}
`;

export const Actions = styled('div')`
  ${() => css`
    display: flex;
    align-items: end;
    justify-content: end;
    padding-right: 15px;

    > button {
      padding: 7px;
    }
  `};
`;

export const SymbolWithTimer = styled(Wrapper)`
  ${() => css`
    > div {
      display: flex;
      align-items: center;

      > svg {
        margin-left: 5px;
        width: 12px;
      }
    }
  `};
`;

export const PlLabel = styled(Wrapper)`
  ${() => css`
    align-items: center;
    justify-content: center;

    > div {
      align-items: center;
      justify-content: center;
      display: flex;
      margin-left: 3px;
    }
  `};
`;
