import { formatByPipSize, returnTypedThis } from '@trader/utils';
import { TInstrumentEntity } from '@trader/store';

import { getRootInstance } from '../configureStore/configureStore';
import { createThunk } from '../utils/asyncModel';
import { api } from '@trader/api';
import {
  analyzeErrorType,
  devLoggerService,
  EErrorTypes,
  instanceOfIError,
} from '@trader/services';
import {
  IInitialTradingFormValues,
  TPlaceOrderSide,
  TProtectionType,
} from '@trader/types';
import { TCommonTradingInstance } from './tradingModel';
import { conversionRate } from '@trader/constants';

interface IGetInstrumentReferenceAsync {
  symbol: string;
  side: TPlaceOrderSide;
}

export const getInstrumentReferenceAsync = createThunk<
  IGetInstrumentReferenceAsync,
  void
>(
  ({ symbol, side }) =>
    async function resolveInstrumentReference(this: unknown, _options, _flow) {
      const that = returnTypedThis<TCommonTradingInstance>(this);
      const root = getRootInstance();

      const entity = root.entities.instruments.get<TInstrumentEntity>(symbol);

      if (entity?.minOrderSize) {
        that.runInAction(() => {
          that.instrument = entity;
          that.side = side;
        });
      } else {
        try {
          const response =
            await root.entities.instruments.getInstrumentSpecificationAsync.run(
              symbol
            );

          if (response?.symbol) {
            that.runInAction(() => {
              that.instrument =
                root.entities.instruments.get<TInstrumentEntity>(symbol);
              that.side = side;
            });
          }
        } catch (error) {
          devLoggerService.error('getInstrumentReferenceAsync', error);
        }
      }
    }
);

export const prePlaceOrderAsync = createThunk<IInitialTradingFormValues, void>(
  ({
    amount,
    price,
    side,
    isTakeProfit,
    isStopLoss,
    takeProfit,
    stopLoss,
    orderType,
  }) =>
    async function prePlaceOrder(this: unknown, _options, _flow) {
      const that = returnTypedThis<TCommonTradingInstance>(this);
      const root = getRootInstance();

      if (!that.instrument) {
        return;
      }

      const isInvestmentAccProduct = root.user.isInvestmentAccProduct();

      const orderModelBE = {
        type: that.orderType,
        symbol: that.instrument.symbol,
        quantity: Number(amount),
        side: that.side,
        currentAsk: +formatByPipSize(
          that.instrument.ask,
          that.instrument.pipSize
        ),
        currentBid: +formatByPipSize(
          that.instrument.bid,
          that.instrument.pipSize
        ),
        price: that.orderType !== 'Market' ? Number(price) : undefined,
        reportPriceChange: true,
        takeProfit: isTakeProfit
          ? {
              type: 'TakeProfit' as TProtectionType,
              limitPrice: Number(takeProfit),
            }
          : undefined,
        stopLoss: isStopLoss
          ? {
              type: 'StopLoss' as TProtectionType,
              stopPrice: Number(stopLoss),
            }
          : undefined,
      };

      try {
        await that.getRequiredOpenCostAsync.run({ amount, price, side });

        root.ui.modal.update({
          isPositionPlaced: orderType === 'Market' && !isInvestmentAccProduct,
          isOrderPlaced:
            orderType === 'EntryLimit' ||
            orderType === 'EntryStop' ||
            isInvestmentAccProduct,
        });

        root.ui.modal.close();

        await root.pages.trading.placeOrderAsync.run(orderModelBE);
        that.updateOrderType('Market');
        that.updateSide(null);
        that.runInAction(() => {
          that.isTakeProfit = false;
          that.isStopLoss = false;
        });
      } catch (error) {
        devLoggerService.error('catch error prePlaceOrderAsync', error);
      }
    }
);

export const getRequiredOpenCostAsync = createThunk<
  Pick<IInitialTradingFormValues, 'amount' | 'price' | 'side'>,
  void
>(
  ({ amount, price, side }) =>
    async function prePlaceOrder(this: unknown, _options, _flow) {
      const that = returnTypedThis<TCommonTradingInstance>(this);

      if (!that.instrument) {
        return;
      }

      try {
        const response = await api.Trading.getRequiredOpenCost(
          {
            symbol: that.instrument.symbol,
            side: side,
            quantity: Number(amount),
            price: that.orderType !== 'Market' ? Number(price) : 0,
          },
          _options
        );

        that.runInAction(() => {
          that.requiredMargin = response?.requiredMargin;
          that.conversionRate = response?.conversionRate || conversionRate;
          that.isRequiredOpenCostError = response?.noMoney;
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        devLoggerService.error('catch error getRequiredOpenCostAsync', error);

        const errors = error?.response?.data?.errors;
        // Check on insufficient funds error
        if (Array.isArray(errors)) {
          for (const err of errors) {
            if (instanceOfIError(err)) {
              const errorType = analyzeErrorType(err);
              if (errorType === EErrorTypes.INSUFFICIENT_FUNDS) {
                that.runInAction(() => {
                  that.isRequiredOpenCostError = true;
                });
              }
            }
          }
        }
        throw error;
      }
    }
);
