import { types, Instance, cast } from 'mobx-state-tree';

import { defMultiplier, defSl } from '@trader/constants';
import { getTime, runInAction } from '@trader/utils';
import {
  createBandOrderAsync,
  deleteMuliBandAsync,
  deleteMuliBandOrdersAsync,
  deleteMuliBandSingleOrderAsync,
  editMuliBandsOrderAsync,
  getExistingDataAsync,
  getInitialDataAsync,
  pingMuliBandAsync,
  editMuliBandsCloseTimeAsync,
} from './actions';
import { EMultiplier } from '@trader/types';

import { bandModel } from './bands';
import { stripModel } from './strip';
import { indicatorsModel } from './indicators';
import { backTesting } from './backTesting';
import { TPositionMetricEntity } from '../../entities/positionsMetrics';

const dataBoxModel = types.model('dataBoxModel', {
  isOpen: false,
  positionTop: types.maybeNull(types.number),
  positionLeft: types.maybeNull(types.number),
});

const stripM = types.model('stripM', {
  id: types.string,
  value: types.optional(types.number, 0),
  cross: types.optional(types.number, 0),
  change: types.optional(types.string, '-'),
  orderId: types.maybeNull(types.number),
  orderSide: types.maybeNull(types.string),
  positionId: types.maybeNull(types.number),
  positionPrice: types.maybeNull(types.number),
  positionPl: types.maybeNull(types.number),
});

const staticMultiplierModel = types.model('staticMultiplierModel', {
  us: types.number,
  base: types.number,
});

export type TStripM = Instance<typeof stripM>;

export const muliBandModel = types
  .model('muliBandModel', {
    id: types.maybeNull(types.number),
    symbol: types.optional(types.string, ''),
    chartPeriod: types.optional(types.string, 'Minute2'),
    orderAmount: types.optional(types.string, '1000'),
    topMultiplier: types.optional(types.string, String(defMultiplier)),
    bottomMultiplier: types.optional(types.string, String(defMultiplier)),
    sl: types.optional(types.string, defSl),
    isSl: false,
    isCloseTime: false,
    closeTime: types.maybeNull(types.Date),
    hasMultiplierChanged: false,
    firstOpenedPositionTime: types.maybeNull(types.string),
    recalculateIndicatorTrigger: types.optional(types.number, 0), // trick for synchronization data with BE
    multiplier: types.optional(
      types.union(
        types.literal(EMultiplier.base),
        types.literal(EMultiplier.us)
      ),
      EMultiplier.base
    ),
    strip: types.optional(stripModel, {}),
    price: types.optional(types.number, 0),
    dataBox: types.optional(dataBoxModel, {}),
    bandsButtons: types.array(bandModel),
    strips: types.map(stripM),
    staticTopMultiplier: types.maybeNull(staticMultiplierModel),
    staticBottomMultiplier: types.maybeNull(staticMultiplierModel),
    indicators: indicatorsModel,
    backTesting: backTesting,
    usSession: types.maybeNull(
      types.model('usSession', {
        from: types.string,
        to: types.string,
      })
    ),
    createBandOrderAsync,
    getInitialDataAsync,
    getExistingDataAsync,
    deleteMuliBandAsync,
    pingMuliBandAsync,
    deleteMuliBandOrdersAsync,
    editMuliBandsOrderAsync,
    deleteMuliBandSingleOrderAsync,
    editMuliBandsCloseTimeAsync,
  })
  .views(store => ({
    getCloseTime: (
      positionId: string,
      strategy: TPositionMetricEntity['strategy']
    ): string | undefined => {
      if (strategy && strategy.closesTradesAfter) {
        return strategy.closesTradesAfter;
      }

      const strips = Array.from<TStripM>(store.strips.values());

      if (store.isCloseTime && store.closeTime) {
        const isPosition = strips.find(
          strip => strip.positionId === Number(positionId)
        );

        return isPosition && getTime(store.closeTime);
      }
    },
  }))
  .actions(store => ({
    runInAction,
    setPrice: (price: number) => {
      store.price = price;
    },
    setMultiplier: (time = new Date()) => {
      const utcDate = new Date(time.toISOString().replace('Z', ''));
      if (store.usSession) {
        const currentTimeUtc = utcDate.getTime();
        const splitedSessionFromTime = store.usSession?.from.split(':');
        const splitedSessionToTime = store.usSession?.to.split(':');

        const fromTimeUtc = new Date();
        fromTimeUtc.setHours(
          Number(splitedSessionFromTime[0]),
          Number(splitedSessionFromTime[1]),
          Number(splitedSessionFromTime[2])
        );
        const toTimeUtc = new Date();
        toTimeUtc.setHours(
          Number(splitedSessionToTime[0]),
          Number(splitedSessionToTime[1]),
          Number(splitedSessionToTime[2])
        );
        if (
          currentTimeUtc >= fromTimeUtc.getTime() &&
          currentTimeUtc <= toTimeUtc.getTime()
        ) {
          store.multiplier = EMultiplier.us;
        } else {
          store.multiplier = EMultiplier.base;
        }
      }
    },

    mergeStrips: (strips: TStripM) => {
      store.strips.merge(strips);
    },
    onRecalculate: () => {
      store.recalculateIndicatorTrigger += 1;
    },
    updateStripOrder: (
      key: string,
      orderId: number | null,
      orderSide?: string
    ) => {
      const strip = store.strips.get(key);
      if (strip) {
        store.strips.set(key, {
          ...strip,
          orderId,
          orderSide: orderSide || strip.orderSide,
        });
      }
    },
    clearBands: () => {
      const strips = Array.from<TStripM>(store.strips.values());

      strips.forEach(s => {
        s.orderId = null;
        s.positionId = null;
      });
    },
    updateStripPosition: (
      key: string,
      positionId: number | null,
      openPrice?: number,
      pl?: number
    ) => {
      const strip = store.strips.get(key);
      if (strip) {
        store.strips.set(key, {
          ...strip,
          positionId,
          positionPrice: openPrice || null,
          positionPl: pl || null,
        });
      }
    },
    clear: () => {
      store.bandsButtons = cast([]);
      store.staticTopMultiplier = null;
      store.sl = defSl;
      store.isSl = false;
      store.id = null;
      store.isCloseTime = false;
      store.closeTime = null;
      store.hasMultiplierChanged = false;
      store.orderAmount = '0';
      store.price = 0;
      store.topMultiplier = String(defMultiplier);
      store.bottomMultiplier = String(defMultiplier);
      store.staticBottomMultiplier = null;
      store.firstOpenedPositionTime = null;
      store.usSession = null;
      store.recalculateIndicatorTrigger = 0;
    },
    updateStripsValues: (
      strips: Array<{
        id: string;
        value: number;
        cross: number;
        change: string;
      }>
    ) => {
      strips.forEach(strip => {
        store.strips.set(strip.id, {
          ...store.strips.get(strip.id),
          ...strip,
        });
      });
    },
    updateDataBoxPosition: (top: number, left: number) => {
      store.dataBox.positionTop = top;
      store.dataBox.positionLeft = left;
    },
    toggleDataBox: () => {
      store.dataBox.isOpen = !store.dataBox.isOpen;
    },
    toggleHasMultiplierChanged: (value: boolean) => {
      store.hasMultiplierChanged = value;
    },
  }));

export const muliBands = types.optional(muliBandModel, {});

export type TMuliBandsStore = Instance<typeof muliBandModel>;
