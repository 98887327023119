import { indexDBName } from './constants';
import { IDataFreeParams, IUpdateOutput } from './types';

export const remove = ({
  key,
  version,
  storeName,
}: IDataFreeParams): Promise<IUpdateOutput> =>
  new Promise(resolve => {
    const request = indexedDB.open(indexDBName, version);

    request.onsuccess = () => {
      const tx = request.result.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      const res = store.delete(key);

      res.onsuccess = () => {
        resolve({ isSuccess: true });
      };

      res.onerror = () => {
        const error = res?.error?.message;
        if (error) {
          resolve({ isSuccess: false, message: error });
        } else {
          resolve({ isSuccess: false, message: 'Unknown error' });
        }
      };
    };
  });
