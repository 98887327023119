import {
  Tabs as MuiTabs,
  Tab as MuiTab,
  AppBar as MuiAppBar,
} from '@mui/material';
import styled, { css } from 'styled-components';

import {
  layoutChildrenRadius,
  tradingHorizontalSpace,
} from '@trader/constants';
import { Wrapper } from '@trader/components';

export const Tabs = styled(MuiTabs)`
  ${({ theme }) => css`
    min-height: 35px;
    height: 35px;
    margin: 2px ${tradingHorizontalSpace}px 8px ${tradingHorizontalSpace}px;
    align-items: center;
    background: ${theme.palette.background.default};
    padding: 0 2px;
    border-radius: 5px;

    span {
      text-transform: capitalize;
    }
  `}
`;

export const Tab = styled(MuiTab)`
  ${({ theme, value }) => css`
    text-transform: capitalize;
    font-size: ${theme.typography.default.fontSize};
    min-height: 31px;
    height: 31px;
    font-weight: ${theme.typography.fontWeightMedium};
    border-radius: 4px;
    flex: ${value === 'Market' ? 1 : 2};
    color: ${theme.palette.common.black};

    &.Mui-selected {
      background: ${theme.palette.background.paper};
      color: ${theme.palette.common.black};
    }

    &.Mui-disabled {
      color: ${theme.palette.tab.disabled};
    }

    ${theme.breakpoints.down('sm')} {
      font-size: ${theme.typography.large.fontSize};
    }
  `};
`;

export const Container = styled(Wrapper)`
  ${({ theme }) => css`
    flex-grow: 1;
    overflow: auto;
    width: 100%;
    background: ${theme.palette.background.paper};
    border-radius: ${layoutChildrenRadius}px;

    height: auto;
    flex-direction: column-reverse;
  `};
`;

export const AppBar = styled(MuiAppBar)`
  ${({ theme }) => css`
    position: static;
    width: auto;
    box-shadow: none;
    background: transparent;

    margin-bottom: 9px;
    border-bottom: 1px solid ${theme.palette.background.default};
  `}
`;
