import loginBackground from './backgroundLogin.webp';
import backgroundLoginCapitalmarkets from './backgroundLoginCapitalmarkets.webp';
import purchaseChallengeChartUpBgImage from './purchaseChallengeChartUpBgImage.png';
import purchaseChallengeChartDownBgImage from './purchaseChallengeChartDownBgImage.png';
import backgroundLogin24funded from './backgroundLogin24funded.webp';

export const images = {
  loginBackground,
  backgroundLoginCapitalmarkets,
  purchaseChallengeChartUpBgImage,
  purchaseChallengeChartDownBgImage,
  backgroundLogin24funded,
};
