import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import {
  TPositionMetricEntity,
  TTradingAccountEntity,
  useMst,
} from '@trader/store';
import {
  Icon,
  Tooltip,
  ScrollableTable,
  ObservableTableColumn,
  IScrollableTableColumn,
  IconButton,
} from '@trader/components';
import { calcPositionPl, formatTableRowValueByPipSize } from '@trader/utils';
import { useExtendItemWidthDependsLng } from '@trader/hooks';
import { MODAL_TYPES } from '@trader/constants';
import { useI18next } from '@trader/services';

import {
  PurchaseType,
  SymbolWithIcon,
  ChangePercentage,
  ProfitValue,
} from '../scrollingList';
import { CloseAllPositions } from './closeAllPositions';
import * as Styled from './styled';

interface IPositionsList {
  symbol?: string;
}

export const PositionsList: React.FC<IPositionsList> = observer(
  ({ symbol }) => {
    const { translate } = useI18next();
    const { getExtendedWidth } = useExtendItemWidthDependsLng();

    const store = useMst();
    const positions =
      store.entities.positionsMetrics.getAll<TPositionMetricEntity>();
    const accounts =
      store.entities.tradingAccounts.getAll<TTradingAccountEntity>();

    const allMetrics = useMemo(() => {
      return symbol ? positions.filter(p => p.symbol === symbol) : positions;
    }, [symbol, positions]);

    useEffect(() => {
      accounts.length &&
        store.entities.positionsMetrics.getPositionsMetricsAsync.run();
    }, [accounts.length]);

    const renderPlHeader = () => (
      <Styled.PlLabel>
        {translate('COMMON.LABELS.P_L')}
        <Tooltip title={translate('TOOLTIPS.PL_WITHOUT_SWAP')}>
          <Icon iconType='info' />
        </Tooltip>
      </Styled.PlLabel>
    );

    const renderActions = item => {
      const position = item.row;

      const openModal = (modalType: keyof typeof MODAL_TYPES) => {
        store.ui.modal.open(modalType, {
          symbol: position.symbol,
          positionId: position.positionId,
          side: position.side,
        });
      };

      return (
        <Styled.Actions>
          <IconButton
            iconType='edit'
            onClick={event => {
              event.stopPropagation();
              openModal(MODAL_TYPES.addProtection);
            }}
          />
          <IconButton
            iconType='remove'
            onClick={event => {
              event.stopPropagation();
              openModal(MODAL_TYPES.closePosition);
            }}
          />
        </Styled.Actions>
      );
    };

    const renderSymbol = (
      s: string,
      url: string,
      strategy: TPositionMetricEntity['strategy'],
      id: string
    ) => {
      const closeTime = store.pages.muliBands.getCloseTime(id, strategy);

      if (closeTime) {
        return (
          <Styled.SymbolWithTimer>
            <SymbolWithIcon symbol={s} iconUrl={url} />
            <Tooltip title={`Close time: ${closeTime}`}>
              <Icon iconType='timer' />
            </Tooltip>
          </Styled.SymbolWithTimer>
        );
      }

      return <SymbolWithIcon symbol={s} iconUrl={url} />;
    };

    const columns: IScrollableTableColumn<TPositionMetricEntity>[] = useMemo(
      () => [
        {
          id: 'symbol',
          header: translate('COMMON.LABELS.SYMBOL'),
          minWidth: 115,
          cellStyle: { paddingLeft: '15px' },
          headerStyle: { textAlign: 'left', paddingLeft: '20px' },
          render: row =>
            renderSymbol(row.symbol, row.iconUrl, row.strategy, row.positionId),
        },
        {
          id: 'positionId',
          header: translate('COMMON.LABELS.POSITION_ID'),
          minWidth: 100,
        },
        {
          id: 'side',
          header: translate('COMMON.LABELS.TYPE'),
          minWidth: getExtendedWidth('60', '2', ['nl']),
          render: row => <PurchaseType value={row.side as string} />,
        },
        {
          id: 'quantity',
          header: translate('COMMON.LABELS.VOLUME'),
          minWidth: 90,
        },
        {
          id: 'pl',
          header: renderPlHeader(),
          minWidth: 100,
          render: row => (
            <ObservableTableColumn
              row={row}
              render={r => <ProfitValue value={calcPositionPl(r).toFixed(2)} />}
            />
          ),
        },
        {
          id: 'netPl',
          header: translate('COMMON.LABELS.NET_PL'),
          minWidth: 100,
          render: row => (
            <ObservableTableColumn
              row={row}
              render={r => (
                <ProfitValue value={(calcPositionPl(r) + r.swap).toFixed(2)} />
              )}
            />
          ),
        },
        {
          id: 'openPrice',
          header: translate('COMMON.LABELS.OPEN_PRICE'),
          minWidth: 120,
          render: row =>
            formatTableRowValueByPipSize(row.openPrice, row.pipSize),
        },
        {
          id: 'currentPrice',
          header: translate('COMMON.LABELS.CURRENT_PRICE'),
          minWidth: 120,
          render: row => (
            <ObservableTableColumn
              row={row}
              render={r => formatTableRowValueByPipSize(r.price, r.pipSize)}
            />
          ),
        },
        { id: 'swap', header: translate('COMMON.LABELS.SWAP'), minWidth: 80 },
        {
          id: 'value',
          header: translate('COMMON.LABELS.VALUE'),
          minWidth: 120,
          render: row => (
            <ObservableTableColumn
              row={row}
              render={r => Number((r.price * r.quantity).toFixed(1))}
            />
          ),
        },
        {
          id: 'stopLoss',
          header: translate('COMMON.LABELS.SL'),
          minWidth: 90,
          render: row =>
            formatTableRowValueByPipSize(row.stopLoss.stopPrice, row.pipSize),
        },
        {
          id: 'takeProfit',
          header: translate('COMMON.LABELS.TP'),
          minWidth: 90,
          render: row =>
            formatTableRowValueByPipSize(
              row.takeProfit.limitPrice,
              row.pipSize
            ),
        },
        {
          id: 'change',
          header: translate('COMMON.LABELS.CHANGE'),
          minWidth: 110,
          render: row => (
            <ObservableTableColumn
              row={row}
              render={r => (
                <ChangePercentage
                  entryValue={r.openPrice}
                  exitValue={r.price}
                  side={r.side}
                />
              )}
            />
          ),
        },
        {
          id: 'actions',
          header: allMetrics.length ? <CloseAllPositions /> : '',
          minWidth: getExtendedWidth('80', '1.35', ['sk']),
          cellStyle: { paddingLeft: '15px' },
          headerStyle: { textAlign: 'right', paddingRight: '20px' },
          render: row => renderActions({ row }),
        },
      ],
      [allMetrics.length]
    );

    return (
      <Styled.Root>
        <ScrollableTable<TPositionMetricEntity>
          data={allMetrics}
          columns={columns}
          emptyMessage={translate('COMMON.LABELS.NO_POSITIONS')}
        />
      </Styled.Root>
    );
  }
);
