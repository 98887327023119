import { IFullParams, IUpdateOutput } from './types';
import { indexDBName } from './constants';

export const add = <T>({
  data,
  version,
  storeName,
  key,
}: IFullParams<T>): Promise<IUpdateOutput> =>
  new Promise(resolve => {
    const request = indexedDB.open(indexDBName, version);

    request.onsuccess = () => {
      const tx = request.result.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      const isExist = key ? store.get(key) : null;

      isExist ? store.put(data, key) : store.add(data, key);

      resolve({ isSuccess: true });
    };

    request.onerror = () => {
      const error = request.error?.message;
      if (error) {
        resolve({ isSuccess: false, message: error });
      } else {
        resolve({ isSuccess: false, message: 'Unknown error' });
      }
    };
  });
