import { TPaymentStatus, TTransferType } from '@trader/api';

import { useTranslationMaps } from './useTranslationMaps';

export const useFilterOptions = () => {
  const { statusTranslationMap, transferTypesTranslationMap } =
    useTranslationMaps();

  const defaultStatusOption = {
    title: statusTranslationMap.Success,
    value: 'Success',
  };
  const selectStatusOptions: {
    title: string;
    value: TPaymentStatus;
  }[] = [
    { title: statusTranslationMap.Failure, value: 'Failure' },
    { title: statusTranslationMap.Pending, value: 'Pending' },
    { title: statusTranslationMap.Canceled, value: 'Canceled' },
    { title: statusTranslationMap.TimeOut, value: 'TimeOut' },
  ];

  const selectTransferTypeOptions: {
    title: string;
    value: TTransferType;
  }[] = [
    { title: transferTypesTranslationMap.Deposit, value: 'Deposit' },
    { title: transferTypesTranslationMap.Withdrawal, value: 'Withdrawal' },
    { title: transferTypesTranslationMap.TransferIn, value: 'TransferIn' },
    { title: transferTypesTranslationMap.TransferOut, value: 'TransferOut' },
    { title: transferTypesTranslationMap.ChargeBack, value: 'ChargeBack' },
    { title: transferTypesTranslationMap.Fee, value: 'Fee' },
  ];

  return {
    defaultStatusOption,
    selectStatusOptions,
    selectTransferTypeOptions,
  };
};
