import { useEffect } from 'react';

import {
  inAppMessagingService,
  IInAppMessage,
  localStorageService,
  useI18next,
} from '@trader/services';
import { useMst } from '@trader/store';
import { api } from '@trader/api';
import { LOCAL_STORAGE_KEYS } from '@trader/constants';

/**
 * Custom hook that sets up in-app notifications.
 */
export const useInAppNotifications = () => {
  const store = useMst();
  const { translate } = useI18next();

  const handleMessage = (message: IInAppMessage) => {
    store.notifications.add({
      message: message.body,
      options: {
        variant: 'info',
        autoHideDuration: 6000,
      },
    });

    // If the user is currently viewing the "Price Alerts" page,
    // fetch the updated list of price alerts to ensure the data is current
    // after a price alert notification has been triggered.
    if (store.pages.trading.assetsView === 'price-alerts') {
      store.entities.alerts.getAlertsAsync.run();
    }
  };

  const initialize = async () => {
    await inAppMessagingService.requestBrowserPermission();

    if (inAppMessagingService.isPermissionGranted()) {
      await inAppMessagingService.proceedMessagingToken();
      const deviceToken = localStorageService.get(
        LOCAL_STORAGE_KEYS.tokens.firebaseMessagingToken
      );

      if (!deviceToken) {
        store.notifications.add({
          message: translate(
            'WARNINGS.NOTIFICATION_SETUP_FAILED_DUE_TO_DEVICE_TOKEN'
          ),
          options: {
            variant: 'warning',
          },
        });
        return;
      }

      try {
        await inAppMessagingService.registerMessagingServiceWorker();
        await api.Notification.initializeDevice(deviceToken);
        inAppMessagingService.handleMessaging(handleMessage);
      } catch (e) {
        store.notifications.add({
          message: translate('WARNINGS.NOTIFICATION_SETUP_FAILED'),
          options: {
            variant: 'error',
          },
        });
      }
    }
  };

  useEffect(() => {
    initialize();
  }, []);
};
