import * as React from 'react';
import { observer } from 'mobx-react-lite';

import * as Styled from './styled';

interface ILeftSideBarMenu {
  isOpen: boolean;
  children: React.ReactNode;
}

export const LeftSideBarMenu: React.FC<ILeftSideBarMenu> = observer(
  ({ isOpen, children }) => {
    return <Styled.Drawer $isOpen={isOpen}>{children}</Styled.Drawer>;
  }
);
