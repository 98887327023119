import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react-lite';

import { Wrapper } from '@trader/components';
import { ICommonOrderType, IInitialTradingFormValues } from '@trader/types';
import { TInstrumentEntity, useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { usePurchase } from '@trader/hooks';
import {
  getMarketValue,
  getRequiredMargin,
  getRequiredOpenCostQuantity,
  getTotalValue,
} from '@trader/utils';

import * as Styled from './styled';

export const minimumPriceFractionDigits = 2;

export const PurchaseDetails: React.FC<ICommonOrderType> = observer(
  ({ type }) => {
    const store = useMst();
    const { translate } = useI18next();
    const purchase = usePurchase();
    const { getValues } = useFormContext<IInitialTradingFormValues>();

    const trading = store.trading.getTrading(type);
    const instrument = trading.instrument as TInstrumentEntity;

    const quantity = getValues().amount;
    const price = getValues().price;
    const side = getValues().side;
    const isMarketType = getValues().orderType === 'Market';

    const accountCurrencySymbol: string =
      store.user.activeTradingAcc().currencySymbol;
    const isInvestmentAccProduct = store.user.isInvestmentAccProduct();
    const amount = useMemo(
      () => getRequiredOpenCostQuantity(quantity, instrument.minOrderSize),
      [quantity, instrument.minOrderSize]
    );

    const requiredMargin = trading?.requiredMargin || 0;
    const conversionRate = trading?.conversionRate;

    const quantityNumber = Number(quantity);
    const priceNumber = Number(price);

    const marketValue = getMarketValue(
      side,
      instrument.ask,
      instrument.bid,
      amount,
      instrument.pipSize
    );

    const totalValue = getTotalValue(
      isMarketType ? marketValue : priceNumber * quantityNumber,
      conversionRate,
      instrument.currencySymbol,
      accountCurrencySymbol
    );

    const convertReqMargin = useMemo(
      () =>
        getRequiredMargin({
          requiredOpenCosts: requiredMargin,
          conversionRate,
          instrumentCurrencySymbol: instrument.currencySymbol,
          accountCurrencySymbol,
          pipSize: minimumPriceFractionDigits,
        }),
      [requiredMargin, conversionRate]
    );

    const renderError = useMemo(() => {
      if (!trading.isRequiredOpenCostError) {
        return;
      }

      const isShowDepositFunds =
        !purchase.shouldHideButtonOnCreateOrder && !purchase.isDisabled;

      return (
        <Wrapper
          width='100%'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
        >
          <Styled.FundsTitle>
            {translate('COMMON.LABELS.INSUFFICIENT_FUNDS')}
          </Styled.FundsTitle>
          {isShowDepositFunds && (
            <Styled.FundsButton onClick={purchase.action}>
              {translate('COMMON.LABELS.DEPOSIT_FUNDS')}
            </Styled.FundsButton>
          )}
        </Wrapper>
      );
    }, [trading.isRequiredOpenCostError, purchase]);

    return (
      <Styled.Root>
        <Styled.TextWrapper>
          <Styled.Title>
            {translate('COMMON.LABELS.TOTAL_VALUE')}:{' '}
          </Styled.Title>
          <Styled.Value>
            {side ? totalValue : translate('COMMON.LABELS.N_A')}
          </Styled.Value>
        </Styled.TextWrapper>
        {!isInvestmentAccProduct && (
          <Styled.TextWrapper>
            <Styled.Title>
              {translate('COMMON.LABELS.REQUIRED_MARGIN')}:
            </Styled.Title>
            <Styled.Value>
              {side ? convertReqMargin : translate('COMMON.LABELS.N_A')}
            </Styled.Value>
          </Styled.TextWrapper>
        )}
        <Styled.TextWrapper mt={1} mb={1}>
          {renderError}
        </Styled.TextWrapper>
      </Styled.Root>
    );
  }
);
