import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import {
  EStores,
  indexDBService,
  localStorageService,
  TLanguageKey,
  useI18next,
} from '@trader/services';
import {
  emptyFn,
  getAccountTypeForConnection,
  LOCAL_STORAGE_KEYS,
  productId,
  strategyName,
} from '@trader/constants';
import { Wrapper } from '@trader/components';
import { EChartLayouts } from '@trader/types';
import { useTradingCentral, useTradingView } from '@trader/hooks';
import { TInstrumentEntity, useMst } from '@trader/store';
import { twoMars } from './twoMarsIndicator';
import { IChartWidgetProps } from './types';
import { muliBands } from './muliBands';
import { useMuliBands, useDisplayTradingMetricsLinesOnChart } from './hooks';
import {
  ChartingLibraryWidgetOptions,
  CustomIndicator,
  IChartingLibraryWidget,
  IChartWidgetApi,
  PineJS,
  ResolutionString,
  ThemeName,
  widget,
} from './charting_library';
import {
  customFormatters,
  disabledFeatures,
  enabledFeatures,
} from './constants';
import Datafeed, {
  invertedResolutionObject,
  resolutionObject,
} from './datafeed';

import './index.css';
import { appConfigUtils } from '@trader/utils';
import { tradingCentral } from './tradingCentralIndicator';

interface IAdvanceChart {
  instrumentSymbol: string;
  isMuliBandsStrategy?: boolean;
  hasVolume?: boolean;
  layoutNumber: EChartLayouts;
  defaultPeriod?: string;
  timeframe?: string;
  extraDisabledFeatures?: string[];
}

export const AdvanceChart: React.FC<IAdvanceChart> = observer(
  ({
    instrumentSymbol,
    layoutNumber,
    defaultPeriod,
    extraDisabledFeatures,
    isMuliBandsStrategy,
    hasVolume,
    timeframe,
  }) => {
    const store = useMst();
    const { translate, currentLng } = useI18next();
    const { subscribe } = useTradingView();
    const activeTradingAccount = store.user.activeTradingAcc();

    useTradingCentral();

    const idToken = store.auth.tokens.idToken;
    const product = store.user.getAccountProduct();
    const muliBandsStore = store.pages.muliBands;

    const chartContainerRef =
      useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const widgetRef = useRef<IChartingLibraryWidget | null>(null);

    const symbol =
      instrumentSymbol ||
      store.pages.trading.getInstrumentSymbolByLayout() ||
      '';
    const instrument =
      store.entities.instruments.get<TInstrumentEntity>(symbol);

    const isTradingCentral = store.user.settings.isDisplayedByUser;
    const isEdgeX = store.user.settings.isEdgeXDisplayedByUser;
    const isXStrategy = store.user.settings.isXStrategyDisplayedByUser;

    const interval =
      store.pages.trading.layout.layouts[layoutNumber].configuration.period;

    const languageCode =
      (currentLng as TLanguageKey) === 'nl' ||
      (currentLng as TLanguageKey) === 'sk'
        ? 'en'
        : (currentLng as Exclude<TLanguageKey, 'nl' | 'sk'>) || 'en';

    const time_frames: IChartWidgetProps['time_frames'] = [
      {
        text: '1d',
        resolution: '60' as ResolutionString,
        description: translate('COMMON.LABELS.1_DAY'),
        title: translate('COMMON.LABELS.1_DAY'),
      },
      {
        text: '5d',
        resolution: '60' as ResolutionString,
        description: translate('COMMON.LABELS.5_DAYS'),
        title: translate('COMMON.LABELS.5_DAYS'),
      },
      {
        text: '1m',
        resolution: '120' as ResolutionString,
        description: translate('COMMON.LABELS.1_MONTH'),
        title: translate('COMMON.LABELS.1_MONTH'),
      },
      {
        text: '3m',
        resolution: '240' as ResolutionString,
        description: translate('COMMON.LABELS.3_MONTHS'),
        title: translate('COMMON.LABELS.3_MONTHS'),
      },
      {
        text: '6m',
        resolution: '360' as ResolutionString,
        description: translate('COMMON.LABELS.6_MONTHS'),
        title: translate('COMMON.LABELS.6_MONTHS'),
      },
    ];

    const getSymbolData = async (smb: string) => {
      const entity = store.entities.instruments.get<TInstrumentEntity>(smb);

      if (entity) {
        return entity;
      }

      await instrument.getInstrumentSpecificationAsync.run(smb);

      return store.entities.instruments.get<TInstrumentEntity>(smb);
    };

    const renderIndicators = (Pine: PineJS) => {
      const indicators: CustomIndicator[] = [];
      if (isTradingCentral) {
        indicators.push(tradingCentral(Pine));
      }
      if (isEdgeX) {
        indicators.push(twoMars(Pine));
      }

      if (isXStrategy) {
        indicators.push(
          muliBands(
            Pine,
            muliBandsStore,
            instrument.pipSize,
            instrument.tickSize
          )
        );
      }

      return Promise.resolve<CustomIndicator[]>(indicators);
    };

    const defaultWidgetOptions: Omit<IChartWidgetProps, 'container'> = {
      symbol,
      theme: store.app.themeMode as ThemeName,
      locale: languageCode,
      interval: defaultPeriod
        ? invertedResolutionObject[defaultPeriod]
        : invertedResolutionObject[interval] || ('60' as ResolutionString), // 1 hour
      datafeed: Datafeed(
        subscribe,
        getSymbolData,
        {
          description: instrument.description,
          pipSize: instrument.pipSize,
          category: instrument.category,
          close: instrument.close || 0,
          sessions: instrument.sessions || [],
        },
        activeTradingAccount,
        hasVolume
      ) as unknown as ChartingLibraryWidgetOptions['datafeed'],
      library_path: '/charting_library/',
      client_id: appConfigUtils.getCurrentBrand(),
      user_id: store.user.profile?.customerId?.toString(),
      fullscreen: true,
      auto_save_delay: 0.1,
      autosize: true,
      timeframe: timeframe || '7D', // 7 days
      disabled_features: (extraDisabledFeatures
        ? [...(disabledFeatures as string[]), ...extraDisabledFeatures]
        : disabledFeatures) as IChartWidgetProps['disabled_features'],
      enabled_features: enabledFeatures,
      time_frames: defaultPeriod ? [] : time_frames,
      custom_formatters: customFormatters,
      custom_indicators_getter: renderIndicators,
      overrides: {
        'paneProperties.legendProperties.showStudyValues': false,
        'mainSeriesProperties.priceAxisProperties.alignLabels': false,
        'mainSeriesProperties.statusViewStyle.symbolTextSource': 'ticker',
        'scalesProperties.showStudyLastValue': false,
        'paneProperties.legendProperties.showBarChange': false,
      },
    };

    useEffect(() => {
      subscribe(async connection => {
        await connection?.send(
          'SubscribeToQuote',
          symbol,
          1,
          productId[product],
          activeTradingAccount.platformLogin,
          getAccountTypeForConnection[activeTradingAccount.accountType]
        );
      });
    }, [idToken]);

    useEffect(() => {
      if (widgetRef && widgetRef.current) {
        widgetRef.current.onChartReady(() => {
          widgetRef?.current?.setSymbol(
            symbol,
            defaultWidgetOptions.interval,
            emptyFn
          );
        });
      }
    }, [symbol]);

    useEffect(() => {
      if (widgetRef && widgetRef.current) {
        widgetRef.current.onChartReady(() => {
          widgetRef?.current?.changeTheme(store.app.themeMode);
        });
      }
    }, [store.app.themeMode]);

    useEffect(() => {
      localStorageService.remove(LOCAL_STORAGE_KEYS.tradingViewTheme);
      const tvWidget = new widget({
        ...defaultWidgetOptions,
        locale: languageCode,
        container: chartContainerRef.current,
      } as ChartingLibraryWidgetOptions);

      widgetRef.current = tvWidget;

      tvWidget.subscribe('onAutoSaveNeeded', () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tvWidget.save(async (ch: any) => {
          await indexDBService.add<IChartWidgetApi>(
            EStores.TRADING_VIEW,
            ch.charts[0],
            layoutNumber
          );
        });
      });

      tvWidget.onChartReady(async () => {
        // to reset time for AutoLogoutWithNoActivity
        tvWidget
          ?.activeChart()
          .crossHairMoved()
          .subscribe(null, _obj => {
            store.auth.userActivity.resetSeconds();
          });

        tvWidget
          .chart()
          .onIntervalChanged()
          .subscribe(null, value => {
            store.pages.trading.layout.layouts.runInAction(() => {
              store.pages.trading.layout.layouts[
                layoutNumber
              ].configuration.period = resolutionObject[value];
            });
          });

        const chartDB = await indexDBService.get<IChartWidgetApi>(
          EStores.TRADING_VIEW,
          layoutNumber
        );

        setTimeout(() => {
          !tvWidget?.activeChart().getAllStudies()?.length &&
            isMuliBandsStrategy &&
            !chartDB &&
            tvWidget?.activeChart().createStudy(strategyName);
        });

        if (chartDB) {
          tvWidget.activeChart().applyStudyTemplate(chartDB);

          setTimeout(() => {
            !tvWidget?.activeChart().getAllStudies()?.length &&
              isMuliBandsStrategy &&
              tvWidget?.activeChart().createStudy(strategyName);

            tvWidget
              ?.activeChart()
              .getAllStudies()
              .forEach(s => {
                if (s.name === strategyName) {
                  !isMuliBandsStrategy &&
                    tvWidget?.activeChart().removeAllStudies();
                  !isMuliBandsStrategy &&
                    tvWidget?.activeChart().removeAllShapes();
                }
              });

            !isTradingCentral &&
              tvWidget
                ?.activeChart()
                .getAllStudies()
                .forEach(s => {
                  if (s.name === 'TradingCentral') {
                    tvWidget?.activeChart().removeEntity(s.id);
                  }
                });

            !isEdgeX &&
              tvWidget
                ?.activeChart()
                .getAllStudies()
                .forEach(s => {
                  if (s.name === 'EdgeX') {
                    tvWidget?.activeChart().removeEntity(s.id);
                  }
                });
          });
        }
      });
    }, [isTradingCentral, isEdgeX, languageCode, isMuliBandsStrategy, idToken]);

    isMuliBandsStrategy
      ? useMuliBands(widgetRef, isMuliBandsStrategy)
      : useDisplayTradingMetricsLinesOnChart(widgetRef);

    return (
      <Wrapper className='advanced-chart-root'>
        <div ref={chartContainerRef} className={'TVChartContainer'} />
      </Wrapper>
    );
  }
);
