import styled, { css } from 'styled-components';

import { Typography, Wrapper } from '@trader/components';
import { layoutChildrenRadius } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-radius: ${layoutChildrenRadius}px;
    background-color: ${theme.palette.common.white};
  `}
`;

export const TotalCount = styled(Wrapper)`
  ${() => css`
    width: 100%;
    padding: 5px 11px;
    align-items: center;
    justify-content: flex-start;
  `}
`;

export const Name = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.typography.fontWeightBold};

    ${theme.breakpoints.down('sm')} {
      text-transform: uppercase;
    }
  `}
`;

export const Count = styled(Typography)`
  ${() => css`
    margin-left: 3px;
  `}
`;
