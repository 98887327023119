import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';

import { StableDesign } from './stableDesign';
import { BetaDesign } from './betaDesign';
import { MobileBetaDesign } from './betaDesign/components/mobileLeftBar';

interface ILeftBar {
  tabsList: string[];
}

export const LeftBar: React.FC<ILeftBar> = observer(({ tabsList }) => {
  const store = useMst();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (store.app.isBetaDesignEnabled()) {
    return isMobile ? <MobileBetaDesign /> : <BetaDesign />;
  }

  return <StableDesign tabsList={tabsList} />;
});
