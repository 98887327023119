import React, { ReactNode, useEffect, useState } from 'react';
import { CheckboxProps } from '@mui/material/Checkbox';

import { ControlInfo } from '../controlInfo';

import * as Styled from './styled';

export interface ICheckbox
  extends Omit<CheckboxProps, 'helperText' | 'label' | 'disabled'> {
  helperText?: string;
  isError?: boolean;
  isDisabled?: boolean;
  label?: string | ReactNode;
  fontSize?: string;
  children?: ReactNode;
  shouldHideControlInfo?: boolean;
  checked?: boolean;
}

interface IState {
  checked: boolean;
}

export const Checkbox = React.forwardRef<HTMLInputElement, ICheckbox>(
  (props, ref) => {
    const [state, setState] = useState<IState>({
      checked: props.checked || false,
    });

    useEffect(() => {
      setState({ checked: !!props.checked });
    }, [props.checked]);

    const handleChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      checked: boolean
    ) => {
      setState({ checked });
      props.onChange && props.onChange(event, checked);
    };

    const handleBlur = (event: React.FocusEvent<HTMLButtonElement>) => {
      props.onBlur && props.onBlur(event);
    };

    return (
      <Styled.Container
        checked={state.checked}
        $isDisabled={props.isDisabled}
        $isError={props.isError}
      >
        <Styled.FormControlLabel
          ref={ref}
          control={
            <Styled.Checkbox
              {...getValidProps(props)}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={props.isDisabled}
              checked={state.checked}
              indeterminate={!!(props.indeterminate && props.checked)} // props.value - mui show filled svg on this mode (design issue)
              color='primary'
            />
          }
          label={<span>{props.children || props.label}</span>}
          slotProps={{
            typography: {
              sx: { fontSize: props.fontSize },
            },
          }}
        />
        <ControlInfo
          isError={props.isError}
          helperText={props.helperText}
          isDisabled={props.isDisabled}
          isHide={props.shouldHideControlInfo}
        />
      </Styled.Container>
    );
  }
);

const getValidProps = (props: ICheckbox): Partial<ICheckbox> => {
  const {
    isError,
    isDisabled,
    helperText,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    indeterminate,
    className,
    shouldHideControlInfo,
    ...rest
  } = props;
  return rest;
};
