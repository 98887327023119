import { Decimal } from 'decimal.js';

import { TPlaceOrderSide } from '@trader/types';

import { formatByPipSize } from './pipSize';
import { getAmplitudeBasedOnTickSize } from './common';

export const getPriceDifference = (stopsLevel: number, pipSize: number) =>
  stopsLevel * pipSize;

export const getLimitPrice = (
  stopsLevel: number,
  tickSize: number,
  pipSize: number,
  bid: number,
  ask: number,
  side: TPlaceOrderSide
) => {
  const difference = getPriceDifference(stopsLevel, pipSize);
  const bidDecimal = new Decimal(bid);
  const askDecimal = new Decimal(ask);

  if (side === 'Sell') {
    return formatByPipSize(
      askDecimal
        .minus(getAmplitudeBasedOnTickSize(difference, tickSize))
        .toNumber(),
      pipSize
    );
  }

  return formatByPipSize(
    bidDecimal
      .plus(getAmplitudeBasedOnTickSize(difference, tickSize))
      .toNumber(),
    pipSize
  );
};
