/* eslint-disable no-param-reassign,no-magic-numbers */
import { getOrderTotalValue, getRequiredMargin } from '@tchtrade/trade-core';

import { TPlaceOrderSide } from '@trader/types';

export { getRequiredMargin };

export const fixTotalValue = 2;
const minimumFactoringDigits = 2;
export const percentageCalculation = 100;

export const fixToTwoDigitAfterDot = (value: number) =>
  value.toFixed(minimumFactoringDigits);

export const getMaxDigitWithDot = (value: number): number => {
  if (!value || value === 1) {
    return 0;
  }
  const maxDigit = value.toString().split('.');
  const valueMax = maxDigit[maxDigit.length - 1];
  return valueMax.length;
};

export const getTotalValue = (
  value: number,
  conversionRate: number,
  currency: string,
  accountCurrency: string
) =>
  getOrderTotalValue({
    price: value,
    conversionRate,
    instrumentCurrencySymbol: currency,
    accountCurrencySymbol: accountCurrency,
  });

export const getNetPl = (pl: number, currencySymbol: string) => {
  const fixedPlToTwoDigit = Number(pl.toFixed(fixTotalValue));
  const textColor =
    fixedPlToTwoDigit === 0
      ? '#000'
      : fixedPlToTwoDigit > 0
      ? '#21932e'
      : '#FF013E';
  return {
    textColor,
    numberValue: fixedPlToTwoDigit,
    stringValue:
      fixedPlToTwoDigit === 0
        ? `${currencySymbol}0.00`
        : pl > 0
        ? `+${currencySymbol}${fixedPlToTwoDigit.toFixed(fixTotalValue)}`
        : `-${currencySymbol}${Math.abs(fixedPlToTwoDigit).toFixed(
            fixTotalValue
          )}`,
  };
};

export const getTotalNetAmount = (
  value: number,
  side: TPlaceOrderSide,
  translate
): string => {
  if (!value) {
    return '0.00';
  }
  return value === 0
    ? value.toString()
    : value > 0
    ? `${translate(`COMMON.LABELS.${side?.toUpperCase()}`)} ${value}`
    : `${translate(`COMMON.LABELS.${side?.toUpperCase()}`)} ${value
        .toString()
        .replace('-', '')}`;
};

export const getPercentOfSecondNumber = (
  firstNumber: number,
  secondNumber: number,
  fractionDigits: number = fixTotalValue
) => {
  if (firstNumber === 0 || secondNumber === 0) {
    return 0;
  }
  return (
    ((firstNumber - secondNumber) / secondNumber) *
    percentageCalculation
  ).toFixed(fractionDigits);
};

export const roundToXDigits =
  (digits: number, tickSize: number) => (value: number) => {
    value = value * Math.pow(10, digits);
    value = Math.round(value);
    value = value / Math.pow(10, digits);
    return Math.round(value / tickSize) * tickSize;
  };

export const addSeparatorToIntegerNumber = (
  value: number | undefined,
  separator = '.'
) => {
  if (!value) {
    return '--';
  }

  // Check if the number is a decimal number.
  if (value % 1 !== 0) {
    return value.toString();
  }

  const formatter = new Intl.NumberFormat('en-US', {
    useGrouping: true,
    maximumFractionDigits: 0,
  });
  return formatter.format(value).replace(/,/g, separator);
};

export const roundDecimals = (value: number): number =>
  Math.round(value * 100) / 100;
