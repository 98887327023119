import { types } from 'mobx-state-tree';

const tradingViewModel = types
  .model('tradingViewModel', {
    shouldDisplayAllLineAdapters: true,
  })
  .actions(store => ({
    toggleShouldDisplayAllLineAdapters() {
      store.shouldDisplayAllLineAdapters = !store.shouldDisplayAllLineAdapters;
    },
  }));

export const tradingView = types.optional(tradingViewModel, {});
