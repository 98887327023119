import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import { Tooltip } from '@trader/components';
import { useI18next } from '@trader/services';
import { TInstrumentEntity, useMst } from '@trader/store';
import { ICommonOrderType, IInitialTradingFormValues } from '@trader/types';

import * as Styled from './styled';

interface IProfit extends ICommonOrderType {
  openPrice?: string;
}

export const Profit: React.FC<IProfit> = observer(({ type, openPrice }) => {
  const store = useMst();
  const { translate } = useI18next();
  const { formState, getValues } = useFormContext<IInitialTradingFormValues>();

  const trading = store.trading.getTrading(type);
  const instrument = trading.instrument as TInstrumentEntity;

  const shouldRecalculateProfit =
    formState.dirtyFields.takeProfit || formState.dirtyFields.takeProfitPips;

  const calculateProfit = () => {
    trading.updateProfit({ ...getValues(), openPrice });
  };

  useEffect(() => {
    if (!shouldRecalculateProfit) {
      calculateProfit();
    }
  }, [
    shouldRecalculateProfit,
    trading.conversionRate,
    getValues().amount,
    getValues().takeProfit,
  ]);

  useEffect(() => {
    if (shouldRecalculateProfit) {
      calculateProfit();
    }
  }, [
    shouldRecalculateProfit,
    instrument.ask,
    instrument.bid,
    getValues().amount,
    getValues().takeProfit,
  ]);

  useEffect(() => {
    calculateProfit();
  }, [getValues().side, getValues().orderType]);

  return (
    <Tooltip title={trading.profit}>
      <Styled.InputLabel>
        {translate('COMMON.LABELS.PROFIT')}: {trading.profit}
      </Styled.InputLabel>
    </Tooltip>
  );
});
