/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, ReactNode } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useTheme } from '@mui/material';
import { TimeView } from '@mui/x-date-pickers/models';
import { InputBaseProps } from '@mui/material/InputBase';
import { TimePicker as TimePickerMui } from '@mui/x-date-pickers/TimePicker';

import { IChangeFieldEvent } from '@trader/types';

import { ControlInfo } from '../controlInfo';

export interface ITimepicker {
  value?: Date | null;
  helperText?: string;
  onChange?: (event: IChangeFieldEvent<Date | null>) => void;
  onFocus?: InputBaseProps['onFocus'];
  onBlur?: InputBaseProps['onBlur'];
  isError?: boolean;
  label?: string | ReactNode;
  name?: string;
  views?: TimeView[];
  shouldHideControlInfo?: boolean;
}

export const TimePicker = ({
  value,
  onChange,
  helperText,
  label,
  isError,
  shouldHideControlInfo,
  name,
  views = ['hours', 'minutes', 'seconds'],
  onBlur,
  onFocus,
}: ITimepicker) => {
  const theme = useTheme();

  return (
    <Fragment>
      <TimePickerMui
        className='time-picker'
        label={label}
        views={views}
        value={value ? (dayjs(value) as any) : undefined}
        ampm={false}
        onChange={v =>
          onChange &&
          onChange({
            target: {
              name: name || '',
              value: v ? (v as unknown as Dayjs).toDate() : null,
            },
          })
        }
        slotProps={{
          textField: {
            onBlur: onBlur,
            onFocus: onFocus,
            sx: {
              '& .MuiInputBase-input': {
                fontSize: theme.typography.medium.fontSize,
                padding: '8px',
              },
              '& .MuiInputLabel-root': {
                fontSize: theme.typography.large.fontSize,
                transform: 'translate(12px, 7px) scale(1)',
                '&.MuiInputLabel-shrink': {
                  transform: 'translate(15px, -10px) scale(0.85)',
                },
              },
              '& .MuiIconButton-root': {
                svg: {
                  width: '19px',
                  height: '19px',
                },
              },
            },
          },
        }}
      />
      <ControlInfo
        isError={isError}
        helperText={helperText}
        isHide={shouldHideControlInfo}
      />
    </Fragment>
  );
};
