import { Decimal } from 'decimal.js';
import { getStepBasedOnTickSize as getStepBasedOnTickSizeCore } from '@tchtrade/trade-core';

import { TPlaceOrderSide } from '@trader/types';

export const getOrderBasePrice = (
  side: TPlaceOrderSide,
  bidPrice: number,
  askPrice: number
) => (side === 'Buy' ? bidPrice : askPrice);

export const getTradingAmplitude = (stopsLevel: number, pipSize: number) =>
  stopsLevel * +pipSize;

export const getStepBasedOnTickSize = getStepBasedOnTickSizeCore;

export const getAmplitudeBasedOnTickSize = (
  amplitude: number,
  tickSize: number
) => (amplitude < tickSize ? tickSize : amplitude);

export const isValueTimesStep = (
  value: string | number,
  step: string | number
) => {
  const decimal = new Decimal(value);
  return decimal.mod(step).equals(0);
};
