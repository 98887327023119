import React, { useCallback } from 'react';
import { useTheme } from '@mui/material';

import { calcPositionPl, getNetPl } from '@trader/utils';
import { adapterLineStyle, MODAL_TYPES } from '@trader/constants';
import { TPositionMetricEntity, useMst } from '@trader/store';
import { useI18next } from '@trader/services';

import {
  IChartingLibraryWidget,
  IPositionLineAdapter,
} from '../../charting_library';
import { useGetAdapterDiff } from '../muliBands/useGetAdapterDiff';

interface IProtection {
  key: string;
  ref: IPositionLineAdapter | undefined;
}

const protectionLineLength = 50;
const takeProfitMap = new Map<string, IProtection>();
const stopLossMap = new Map<string, IProtection>();

export const useDisplayPositionProtection = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>
) => {
  const store = useMst();
  const theme = useTheme();
  const { translate } = useI18next();

  const { typeColor } = useGetAdapterDiff('Position');

  const createPositionProtection = (id: string) => {
    const position =
      store.entities.positionsMetrics.get<TPositionMetricEntity>(id);

    const currentPl = getNetPl(position.pl, '');

    if (position?.takeProfit?.limitPrice) {
      takeProfitMap.set(id, {
        key: id,
        ref: widget?.current
          ?.activeChart()
          .createPositionLine()
          .setText(currentPl.stringValue)
          .setBodyTextColor(currentPl.textColor)
          .setTooltip(translate('COMMON.LABELS.TAKE_PROFIT'))
          .setProtectTooltip(translate('COMMON.LABELS.STOP_LOSS'))
          .setCloseButtonBorderColor(typeColor(id, position.side))
          .setCloseButtonBackgroundColor(typeColor(id, position.side))
          .setBodyBorderColor(theme.palette.tab.light)
          .setBodyBackgroundColor(theme.palette.tab.light)
          .setCloseButtonIconColor(theme.palette.white.main)
          .setLineColor(typeColor(id, position.side))
          .setLineLength(protectionLineLength)
          .setLineStyle(adapterLineStyle)
          .setQuantity(
            `${translate('COMMON.LABELS.TAKE_PROFIT')}; ID: ${
              position.positionId
            }`
          )
          .setQuantityBackgroundColor(theme.palette.green.main)
          .setQuantityBorderColor(theme.palette.green.main)
          .onModify(function () {
            store.ui.modal.open(MODAL_TYPES.addProtection, {
              symbol: position.symbol,
              positionId: position.positionId,
              side: position.side,
            });
          })
          .setPrice(position.takeProfit.limitPrice),
      });
    }

    if (position?.stopLoss?.stopPrice) {
      stopLossMap.set(id, {
        key: id,
        ref: widget?.current
          ?.activeChart()
          .createPositionLine()
          .setText(currentPl.stringValue)
          .setBodyTextColor(currentPl.textColor)
          .setCloseTooltip(translate('COMMON.LABELS.ADD_PROTECTION'))
          .setTooltip(translate('COMMON.LABELS.STOP_LOSS'))
          .setProtectTooltip(translate('COMMON.LABELS.STOP_LOSS'))
          .setCloseButtonBorderColor(typeColor(id, position.side))
          .setCloseButtonBackgroundColor(typeColor(id, position.side))
          .setBodyBorderColor(theme.palette.tab.light)
          .setBodyBackgroundColor(theme.palette.tab.light)
          .setCloseButtonIconColor(theme.palette.white.main)
          .setLineColor(typeColor(id, position.side))
          .setLineLength(protectionLineLength)
          .setLineStyle(adapterLineStyle)
          .setQuantity(
            `${translate('COMMON.LABELS.STOP_LOSS')}; ID: ${
              position.positionId
            }`
          )
          .setQuantityBackgroundColor(theme.palette.red.light)
          .setQuantityBorderColor(theme.palette.red.light)
          .onModify(function () {
            store.ui.modal.open(MODAL_TYPES.addProtection, {
              symbol: position.symbol,
              positionId: position.positionId,
              side: position.side,
            });
          })
          .setPrice(position?.stopLoss?.stopPrice),
      });
    }
  };

  const clearPositionProtection = useCallback(() => {
    Array.from(takeProfitMap.values()).forEach(tp => {
      tp?.ref?.remove();
    });
    Array.from(stopLossMap.values()).forEach(sl => {
      sl?.ref?.remove();
    });
    takeProfitMap.clear();
    stopLossMap.clear();
  }, []);

  const updatePositionProtection = (id: string) => {
    const position =
      store.entities.positionsMetrics.get<TPositionMetricEntity>(id);

    if (!position) {
      takeProfitMap.get(id)?.ref?.remove();
      stopLossMap.get(id)?.ref?.remove();
      takeProfitMap.delete(id);
      stopLossMap.delete(id);
      return;
    }

    if (!position?.takeProfit?.limitPrice) {
      takeProfitMap.get(id)?.ref?.remove();
      takeProfitMap.delete(id);
    } else {
      const ref = takeProfitMap.get(id)?.ref;
      if (ref) {
        const pl = calcPositionPl(position);
        const currentPl = getNetPl(pl, '');

        ref.setText(currentPl.stringValue);
        ref.setPrice(position.takeProfit.limitPrice);
        ref.setQuantity(
          `${translate('COMMON.LABELS.TAKE_PROFIT')}; ID: ${
            position.positionId
          }`
        );
      } else {
        createPositionProtection(id);
      }
    }

    if (!position?.stopLoss?.stopPrice) {
      stopLossMap.get(id)?.ref?.remove();
      stopLossMap.delete(id);
    } else {
      const ref = stopLossMap.get(id)?.ref;
      if (ref) {
        const pl = calcPositionPl(position);
        const currentPl = getNetPl(pl, '');

        ref.setText(currentPl.stringValue);
        ref.setPrice(position.stopLoss?.stopPrice);
        ref.setQuantity(
          `${translate('COMMON.LABELS.STOP_LOSS')}; ID: ${position.positionId}`
        );
      } else {
        createPositionProtection(id);
      }
    }
  };

  return {
    createPositionProtection,
    clearPositionProtection,
    updatePositionProtection,
  };
};
