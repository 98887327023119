import styled, { css } from 'styled-components';

import { Typography, Button as CoreButton, Wrapper } from '@trader/components';

export const Button = styled(CoreButton)<{
  $isAsk?: boolean;
}>`
  ${({ theme, $isAsk }) => css`
    width: 130px;
    height: 30px;
    border-radius: 5px;
    padding: 4px 8px;
    background: ${$isAsk
      ? theme.palette.purchaseButtons.red
      : theme.palette.purchaseButtons.green};

    &:hover {
      background: ${$isAsk
        ? theme.palette.purchaseButtons.red
        : theme.palette.purchaseButtons.green};
    }
  `};
`;

export const Content = styled(Wrapper)`
  ${() => css`
    width: 100%;
    justify-content: space-between;
    align-items: center;
  `};
`;

export const Label = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.typography.fontWeightBold};
    text-transform: capitalize;
    color: ${theme.palette.white.main};
  `};
`;

export const Price = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    text-transform: capitalize;
    color: ${theme.palette.white.main};
  `};
`;
