import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-color: ${theme.palette.common.white};
    border-radius: 5px;
  `};
`;
