import {
  getAllowedOperations as getAllowedOperationsCore,
  AvailableOperations,
  OrderSide,
  TradeMode,
} from '@tchtrade/trade-core';

import { ETradeMode, TPlaceOrderSide } from '@trader/types';

export const getAllowedOperations = (
  tradeMode: ETradeMode,
  side: TPlaceOrderSide
): AvailableOperations => {
  return getAllowedOperationsCore(
    tradeMode as unknown as TradeMode,
    OrderSide[side as OrderSide]
  );
};
