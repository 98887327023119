import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Modals, TopBar, BottomBar } from '@trader/containers';
import { useInstruments } from '@trader/hooks';
import { useMst } from '@trader/store';

import * as Styled from './styled';

export const TradingLayout: React.FC = observer(() => {
  const store = useMst();
  const symbol = store.pages.trading.getInstrumentSymbolByLayout();

  useInstruments([symbol], { isDataLoaded: true });

  return (
    <Fragment>
      <Styled.Root>
        <TopBar />
        <Styled.Main $isSidebarOpen>
          <Styled.Body>
            <Styled.Routing>
              <Outlet />
            </Styled.Routing>
          </Styled.Body>
        </Styled.Main>
        <BottomBar />
      </Styled.Root>
      <Modals />
    </Fragment>
  );
});
