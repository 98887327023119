import React from 'react';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useMediaQuery, useTheme } from '@mui/material';

import {
  LeftBar,
  Modals,
  TopBar,
  BottomBar,
  RightBar,
} from '@trader/containers';
import { usePrefetchInformation } from '@trader/hooks';
import { useMst } from '@trader/store';
import { cfdTabs } from '@trader/constants';

import * as Styled from './styled';

export const MainLayout: React.FC = observer(() => {
  usePrefetchInformation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const store = useMst();
  const leftBarWidth = store.ui.sidebar.leftBar.width;
  const shouldShowRightBar =
    !store.ui.sidebar.isRightBarHidden() &&
    store.app.isBetaDesignEnabled() &&
    !isMobile;

  return (
    <React.Fragment>
      <Styled.Root>
        <TopBar />
        <Styled.Main $isSidebarOpen>
          <Styled.Body>
            <LeftBar tabsList={cfdTabs} />
            {!isMobile && (
              <Styled.Content
                $leftBarWidth={leftBarWidth}
                $showRightBar={shouldShowRightBar}
              >
                <Outlet />
              </Styled.Content>
            )}
            {shouldShowRightBar && <RightBar />}
          </Styled.Body>
        </Styled.Main>
        <BottomBar />
      </Styled.Root>
      <Modals />
    </React.Fragment>
  );
});
