import { TPaymentStatus, TTransferType } from '@trader/api';
import { useI18next } from '@trader/services';

export const useTranslationMaps = () => {
  const { translate } = useI18next();

  const statusTranslationMap: Record<TPaymentStatus, string> = {
    Success: translate('COMMON.LABELS.SUCCESS'),
    Failure: translate('COMMON.LABELS.FAILURE'),
    Pending: translate('COMMON.LABELS.PENDING'),
    Canceled: translate('COMMON.LABELS.CANCELED'),
    TimeOut: translate('COMMON.LABELS.TIME_OUT'),
  };

  const transferTypesTranslationMap: Record<TTransferType, string> = {
    Deposit: translate('TRANSFER_TYPES.DEPOSIT'),
    Withdrawal: translate('TRANSFER_TYPES.WITHDRAWAL'),
    TransferIn: translate('TRANSFER_TYPES.TRANSFER_IN'),
    TransferOut: translate('TRANSFER_TYPES.TRANSFER_OUT'),
    ChargeBack: translate('TRANSFER_TYPES.CHARGE_BACK'),
    Fee: translate('TRANSFER_TYPES.FEE'),
  };

  return {
    statusTranslationMap,
    transferTypesTranslationMap,
  };
};
