import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { Header } from '@trader/components';

import { MobileTopBar } from './components/mobileTopBar';
import { DesktopTopBar } from './components/desktopTopBar';

interface ITopBar {
  withChartLayoutsSelection?: boolean;
}

export const TopBar: React.FC<ITopBar> = ({
  withChartLayoutsSelection = true,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Header>
      {isMobile ? (
        <MobileTopBar />
      ) : (
        <DesktopTopBar withChartLayoutsSelection={withChartLayoutsSelection} />
      )}
    </Header>
  );
};
