import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    width: 100%;
    height: 100%;
  `};
`;
