import { useEffect } from 'react';

import { TInstrumentEntity, useMst } from '@trader/store';

export const useTradingCentral = () => {
  const store = useMst();

  const isTradingCentral = store.user.settings.isDisplayedByUser;

  const symbol = store.pages.trading.getInstrumentSymbolByLayout();

  useEffect(() => {
    if (isTradingCentral && symbol) {
      const instrument =
        store.entities.instruments.get<TInstrumentEntity>(symbol);

      instrument.getInstrumentTradingCentralAsync.run();
    }
  }, [isTradingCentral, symbol]);
};
