import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTheme } from 'styled-components';

import {
  layoutMargin,
  minimizedBottomBarHeight,
  bottomBarHeight,
} from '@trader/constants';
import {
  ExpandedContent,
  ITab,
  Progress,
  Tabs,
  THeightMode,
  Tooltip,
  Wrapper,
} from '@trader/components';
import { inAppMessagingService, useI18next } from '@trader/services';
import { useMst } from '@trader/store';
import { useResponsiveDesign } from '@trader/hooks';

import { IAssetDetails } from '../index';
import * as Styled from './styled';

const AlertsListLazy = React.lazy(() =>
  import('@trader/components').then(({ AlertsList }) => ({
    default: AlertsList,
  }))
);
const PositionsListLazy = React.lazy(() =>
  import('@trader/components').then(({ PositionsList }) => ({
    default: PositionsList,
  }))
);
const PendingOrdersListLazy = React.lazy(() =>
  import('@trader/components').then(({ PendingOrdersList }) => ({
    default: PendingOrdersList,
  }))
);
const PositionsHistoryLazy = React.lazy(() =>
  import('@trader/components').then(({ PositionsHistory }) => ({
    default: PositionsHistory,
  }))
);

const defaultMaxHeight = 650;
const defaultMinHeight = 120;

export const BetaDesign: React.FC<IAssetDetails> = observer(
  ({
    selectedTab,
    navigationKey = 'assetsView',
    assetPage = 'dashboard',
    excludedTabs,
    additionalTabs,
    minHeight,
  }) => {
    const store = useMst();
    const theme = useTheme();
    const { translate } = useI18next();
    const { bottomBar } = useResponsiveDesign();

    const isInvestmentAccProduct = store.user.isInvestmentAccProduct();
    const height = store.ui.sidebar.bottomBar[assetPage].height;
    const heightAfterMinimized = Math.max(
      height,
      bottomBarHeight,
      defaultMinHeight,
      minHeight || 0
    );

    const positions = store.entities.positionsMetrics.getAll();
    const orders = store.entities.ordersMetrics.getAll();
    const alerts = store.entities.alerts.getAll();

    const styles = {
      marginTop: `${layoutMargin}px`,
      '.MuiTabs-root': {
        padding: '0 8px',
        marginTop: '-8px',
      },
      table: {
        borderTop: 'none',
      },

      '.scroll-list-header': {
        height: '32px',

        th: {
          borderBottom: `1px solid ${theme.palette.background.default}!important`,
          backgroundColor: `${theme.palette.background.paper}!important`,

          '> span': {
            cursor: 'pointer',
            color: `${theme.palette.red.light}`,
            fontSize: `${theme.typography.tiny.fontSize}`,
            fontWeight: '500',
          },
        },
      },
    };

    useEffect(() => {
      if (bottomBar.shouldMinimizedHeight) {
        store.ui.sidebar.updateBottomBarHeight(
          minimizedBottomBarHeight,
          assetPage
        );
      } else {
        store.ui.sidebar.updateBottomBarHeight(heightAfterMinimized, assetPage);
      }
    }, [bottomBar.shouldMinimizedHeight]);

    const getTabs = () => {
      const positionsLabel = positions.length
        ? `${translate('COMMON.LABELS.POSITIONS')} ${positions.length}`
        : translate('COMMON.LABELS.POSITIONS');

      const ordersLabel = orders.length
        ? `${translate('COMMON.LABELS.PENDING_ORDERS')} ${orders.length}`
        : translate('COMMON.LABELS.PENDING_ORDERS');

      const alertsLabel = alerts.length
        ? `${translate('COMMON.LABELS.PRICE_ALERTS')} ${alerts.length}`
        : translate('COMMON.LABELS.PRICE_ALERTS');

      const items: ITab[] = [
        {
          label: positionsLabel,
          value: 'positions',
          children: (
            <React.Suspense fallback={<Progress />}>
              <PositionsListLazy />
            </React.Suspense>
          ),
        },
        {
          label: translate('COMMON.LABELS.HISTORY'),
          value: 'history',
          children: (
            <React.Suspense fallback={<Progress />}>
              <PositionsHistoryLazy />
            </React.Suspense>
          ),
        },
        {
          label: (
            <Wrapper alignItems='center'>
              <span>{alertsLabel}</span>
              {!inAppMessagingService.isPermissionGranted() &&
                !!alerts.length && (
                  <Tooltip title={translate('WARNINGS.NOTIFICATION_DISABLED')}>
                    <Styled.WarningIcon iconType='warning' />
                  </Tooltip>
                )}
            </Wrapper>
          ),
          value: 'price-alerts',
          children: (
            <React.Suspense fallback={<Progress />}>
              <AlertsListLazy />
            </React.Suspense>
          ),
        },
      ];

      if (!isInvestmentAccProduct) {
        items.splice(1, 0, {
          label: ordersLabel,
          value: 'pending-orders',
          children: (
            <React.Suspense fallback={<Progress />}>
              <PendingOrdersListLazy />
            </React.Suspense>
          ),
        });
      }

      return [
        ...items.filter(item => !excludedTabs?.includes(item.value)),
        ...(additionalTabs ?? []),
      ];
    };

    if (store.ui.sidebar.bottomBar[assetPage].isShrinked) {
      return (
        <Styled.Shrinked>
          <Styled.Toggle
            $isShrinked
            iconType='minimize'
            onClick={() => store.ui.sidebar.expandBottomBar(assetPage)}
          />
          <Tabs
            tabsPosition='top'
            tabs={getTabs()}
            navigationKey={navigationKey}
            preselectedTab={selectedTab}
            onChange={() => {
              store.ui.sidebar.expandBottomBar(assetPage);
              store.ui.sidebar.updateBottomBarHeight(
                bottomBar.shouldMinimizedHeight
                  ? minimizedBottomBarHeight
                  : heightAfterMinimized,
                assetPage
              );
            }}
            marginBottom='0px'
          />
        </Styled.Shrinked>
      );
    }

    return (
      <ExpandedContent<THeightMode>
        height={height}
        minHeight={minHeight || defaultMinHeight}
        maxHeight={defaultMaxHeight}
        disabled={bottomBar.shouldDisabledChangingHeight}
        onUpdateHeight={(h: number) =>
          store.ui.sidebar.updateBottomBarHeight(h, assetPage)
        }
        sx={styles}
      >
        <Styled.Toggle
          $isShrinked={false}
          iconType='maximize'
          onClick={() => store.ui.sidebar.shrinkBottomBar(assetPage)}
        />
        <Styled.Title>
          {translate('COMMON.LABELS.ORDERS_AND_ALERTS')}
        </Styled.Title>
        <Tabs
          tabsPosition='top'
          tabs={getTabs()}
          navigationKey={navigationKey}
          preselectedTab={selectedTab}
          rootStyles={{ marginTop: '8px', overflow: 'hidden' }}
          marginBottom='0px'
        />
      </ExpandedContent>
    );
  }
);
