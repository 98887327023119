import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import { TMuliBandsStore, useMst } from '@trader/store';
import { emptyFn } from '@trader/constants';
import { Form } from '@trader/components';

import { useOrderFormValidation } from './formValidation';
import { PositionsCloseTime } from '../../../positionsCloseTime';

import * as Styled from './styled';

const Content: React.FC = observer(() => {
  const store = useMst();

  const muliBands: TMuliBandsStore = store.pages.muliBands;

  const { setValue } = useFormContext<ISettingsInitialValues>();

  useEffect(() => {
    setValue('isCloseTime', muliBands.isCloseTime);
    setValue('closeTime', muliBands.closeTime || undefined);
  }, [muliBands.id]);

  return (
    <Styled.Root>
      <Styled.CloseTime>
        <PositionsCloseTime />
      </Styled.CloseTime>
    </Styled.Root>
  );
});

interface ISettingsInitialValues {
  isCloseTime: boolean;
  closeTime: Date | undefined;
}

export const Settings: React.FC = observer(() => {
  const store = useMst();
  const validationSchema = useOrderFormValidation();

  const muliBands = store.pages.muliBands;

  const defaultFormValues: ISettingsInitialValues = {
    isCloseTime: muliBands.isCloseTime,
    closeTime: muliBands.closeTime || undefined,
  };

  const handleWatch = (name: string, value: string | number | boolean) => {
    muliBands.runInAction(() => {
      muliBands[name] = value;
    });
  };

  return (
    <Form<ISettingsInitialValues>
      onSubmit={emptyFn}
      mode='all'
      defaultValues={defaultFormValues}
      validationSchema={validationSchema}
      handleWatch={handleWatch}
    >
      {() => <Content />}
    </Form>
  );
});
