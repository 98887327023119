import { useEffect, useState, FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useTheme } from 'styled-components';

import { TInstrumentEntity, TTradingHoursModel, useMst } from '@trader/store';
import { IInstrumentTradersTrendBE } from '@trader/api';
import { Tooltip, Typography, Wrapper } from '@trader/components';
import { useI18next } from '@trader/services';
import {
  addSeparatorToIntegerNumber,
  formatByPipSize,
  getInstrumentDifference,
  weekDays,
} from '@trader/utils';

import * as Styled from './styled';

export const KeyStatistics = observer(() => {
  const { translate } = useI18next();
  const store = useMst();

  const symbol =
    store.trading.getTrading('createOrder').instrument?.symbol ||
    store.pages.trading.getInstrumentSymbolByLayout();

  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  if (!instrument) {
    return (
      <Typography variant='large' marginTop='4px' component='div'>
        {translate('COMMON.LABELS.INSTRUMENT_IS_NOT_AVAILABLE')}
      </Typography>
    );
  }

  return (
    <>
      <TradersTrend symbol={symbol} instrument={instrument} />
      <DailyStats instrument={instrument} />
      <TradingConditions instrument={instrument} />
      <Swaps instrument={instrument} />
      <SwapRates instrument={instrument} />
    </>
  );
});

interface ITradersTrend {
  symbol: string;
  instrument: TInstrumentEntity;
}

const initialTradersTrend = {
  sold: 0,
  bought: 0,
};
const TradersTrend: FC<ITradersTrend> = observer(({ symbol, instrument }) => {
  const { translate } = useI18next();

  const [tradersTrend, setTradersTrend] =
    useState<IInstrumentTradersTrendBE>(initialTradersTrend);

  /**
   * Handle fetching the traders trend information.
   */
  useEffect(() => {
    async function getTradersTrend() {
      if (!instrument || !symbol) return;
      setTradersTrend(initialTradersTrend);
      const trends = await instrument.getInstrumentTradersTrendAsync.run(
        symbol
      );
      trends && setTradersTrend(trends);
    }

    getTradersTrend();
  }, [symbol]);

  return (
    <>
      <Styled.Title $withoutTopSpacing>
        {translate('COMMON.LABELS.TRADERS_TREND')}
      </Styled.Title>
      <Styled.TradersTrendRoot>
        <Styled.Trend
          $isFetched={
            !instrument.getInstrumentSpecificationAsync.inProgress ||
            !instrument.getInstrumentTradersTrendAsync.inProgress
          }
          $type='sold'
          $isGray={!tradersTrend.sold && !tradersTrend.bought}
          $width={tradersTrend.sold}
        >
          <Styled.TrendPrice className='sold'>
            {tradersTrend.sold}%
          </Styled.TrendPrice>
          <Styled.TrendTitle>
            {translate('COMMON.LABELS.SOLD')}
          </Styled.TrendTitle>
        </Styled.Trend>
        <Styled.Trend
          $isFetched={
            !instrument.getInstrumentSpecificationAsync.inProgress ||
            !instrument.getInstrumentTradersTrendAsync.inProgress
          }
          $type='bought'
          $isGray={!tradersTrend.sold && !tradersTrend.bought}
          $width={tradersTrend.bought}
        >
          <Styled.TrendPrice className='bought'>
            {tradersTrend.bought}%
          </Styled.TrendPrice>
          <Styled.TrendTitle>
            {translate('COMMON.LABELS.BOUGHT')}
          </Styled.TrendTitle>
        </Styled.Trend>
      </Styled.TradersTrendRoot>
    </>
  );
});

interface IDailyStats {
  instrument: TInstrumentEntity;
}

const DailyStats: FC<IDailyStats> = observer(({ instrument }) => {
  const { translate } = useI18next();
  const theme = useTheme();

  const currentPrice = Number(instrument.ask || 0);
  const high =
    currentPrice > instrument.high || 0 ? currentPrice : instrument.high || 0;
  const low =
    currentPrice < instrument.low || 0 ? currentPrice : instrument.low || 0;
  const { openCloseDifferenceInPercent, openCloseDifference } =
    getInstrumentDifference(
      instrument.ask,
      instrument.close,
      instrument.pipSize
    );

  const items = [
    {
      label: translate('COMMON.LABELS.OPEN'),
      value: formatByPipSize(instrument.open, instrument.pipSize),
    },
    {
      label: translate('COMMON.LABELS.HIGH'),
      labelColor: theme.palette.purchaseButtons.green,
      value: formatByPipSize(high, instrument.pipSize),
    },
    {
      label: translate('COMMON.LABELS.LOW'),
      labelColor: theme.palette.purchaseButtons.red,
      value: formatByPipSize(low, instrument.pipSize),
    },
    {
      label: translate('COMMON.LABELS.CLOSE'),
      value: formatByPipSize(instrument.close, instrument.pipSize),
    },
    {
      label: translate('COMMON.LABELS.CHANGE'),
      value: openCloseDifference,
    },
    {
      label: `${translate('COMMON.LABELS.CHANGE')} %`,
      value: `${openCloseDifferenceInPercent}%`,
    },
    {
      label: translate('COMMON.LABELS.DAILY_CHANGE'),
      value: `${formatByPipSize(
        instrument?.ask,
        instrument.pipSize
      )} - ${formatByPipSize(instrument.close, instrument.pipSize)}`,
    },
  ];

  return (
    <>
      <Styled.Title>{translate('COMMON.LABELS.DAILY_STATS')}</Styled.Title>
      {items.map((item, index) => (
        <Styled.Row key={item.label} $withTopBorder={index === 0}>
          <Tooltip title={item.label}>
            <Styled.Label $color={item.labelColor}>{item.label}</Styled.Label>
          </Tooltip>
          <Tooltip title={item.value}>
            <Styled.Value>{item.value}</Styled.Value>
          </Tooltip>
        </Styled.Row>
      ))}
    </>
  );
});

interface ITradingConditions {
  instrument: TInstrumentEntity;
}

const TradingConditions: FC<ITradingConditions> = observer(({ instrument }) => {
  const { translate } = useI18next();

  const getTradingHours = () => {
    return Object.entries(instrument.sessions).map(([day, value]) => {
      const hours: TTradingHoursModel[] = value.tradingHours;

      return {
        dayName: translate(`DAYS.${weekDays[day].toUpperCase()}`) as string,
        dayValue: hours.length
          ? hours.map(time => `${time.from || '--'} - ${time.to || '--'}`)
          : ['--'],
      };
    });
  };

  const getRolloverDate = (rolloverDate: string | null) => {
    if (!rolloverDate) {
      return '--';
    }

    const [day, monthAbbr] = rolloverDate.split('-');
    const date = new Date(`2000-${monthAbbr}-${day}`);
    const fullMonth = date.toLocaleString('en', { month: 'long' });
    return `${day} ${fullMonth}`;
  };

  const items = [
    {
      label: translate('COMMON.LABELS.TRADING_STATUS'),
      value: instrument.isOpenTrading
        ? translate('COMMON.LABELS.OPEN_FOR_TRADING')
        : translate('COMMON.LABELS.CLOSED_FOR_TRADING'),
    },
    {
      label: translate('COMMON.LABELS.ROLLOVER_DATE'),
      value: getRolloverDate(instrument.rolloverDate),
    },
    {
      label: translate('COMMON.LABELS.CONTRACT_SIZE'),
      value: addSeparatorToIntegerNumber(instrument.contractSize),
    },
  ];

  return (
    <>
      <Styled.Title>
        {translate('COMMON.LABELS.TRADING_CONDITIONS')}
      </Styled.Title>
      {getTradingHours().map((item, index) => (
        <Styled.Row key={item.dayName} $withTopBorder={index === 0}>
          <Styled.Label $width={item.dayName.length}>
            {item.dayName}
          </Styled.Label>
          <Wrapper flexDirection='column'>
            {item.dayValue.map(dv => (
              <Styled.Value key={dv}>{dv}</Styled.Value>
            ))}
          </Wrapper>
        </Styled.Row>
      ))}
      {items.map(item => (
        <Styled.Row key={item.label} $withTopBorder={false}>
          <Tooltip title={item.label}>
            <Styled.Label $width={item.label.length}>{item.label}</Styled.Label>
          </Tooltip>
          <Tooltip title={item.value}>
            <Styled.Value>{item.value}</Styled.Value>
          </Tooltip>
        </Styled.Row>
      ))}
    </>
  );
});

interface ISwaps {
  instrument: TInstrumentEntity;
}

const Swaps: FC<ISwaps> = observer(({ instrument }) => {
  const { translate } = useI18next();

  const swapTypeTitle = !instrument.swapType
    ? '--'
    : translate(`SWAP_TYPES.${instrument.swapType}`);

  const items = [
    {
      label: translate('COMMON.LABELS.SWAP_TYPE'),
      value: swapTypeTitle,
    },
    {
      label: translate('COMMON.LABELS.SWAP_LONG'),
      value: instrument.swapRateLong,
    },
    {
      label: translate('COMMON.LABELS.SWAP_SHORT'),
      value: instrument.swapRateShort,
    },
  ];

  return (
    <>
      <Styled.Title>{translate('COMMON.LABELS.SWAPS')}</Styled.Title>
      {items.map((item, index) => (
        <Styled.Row key={item.label} $withTopBorder={index === 0}>
          <Tooltip title={item.label}>
            <Styled.Label>{item.label}</Styled.Label>
          </Tooltip>
          <Tooltip title={item.value}>
            <Styled.Value>{item.value}</Styled.Value>
          </Tooltip>
        </Styled.Row>
      ))}
    </>
  );
});

interface ISwapRates {
  instrument: TInstrumentEntity;
}

const SwapRates: FC<ISwapRates> = observer(({ instrument }) => {
  const { translate } = useI18next();

  return (
    <>
      <Styled.Title>{translate('COMMON.LABELS.SWAPS_RATES')}</Styled.Title>
      {weekDays.map((day, index) => (
        <Styled.Row key={day} $withTopBorder={index === 0}>
          <Tooltip title={translate(`DAYS.${day.toUpperCase()}`)}>
            <Styled.Label>
              {translate(`DAYS.${day.toUpperCase()}`)}
            </Styled.Label>
          </Tooltip>
          <Tooltip title={instrument.swapRates[index]}>
            <Styled.Value>{instrument.swapRates[index]}</Styled.Value>
          </Tooltip>
        </Styled.Row>
      ))}
    </>
  );
});
