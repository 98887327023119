import { getIconBaseProps } from './utils';

export const belowPrice = {
  ...getIconBaseProps('belowPrice'),
  width: '10px',
  height: '11px',
  viewBox: '0 0 10 11',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M5 1.125L5 9.875'
        stroke='#F73F4A'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.5 6.375L5 9.875L1.5 6.375'
        stroke='#F73F4A'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
};
