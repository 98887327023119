import styled, { css } from 'styled-components';

import { tradingHorizontalSpace } from '@trader/constants';
import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 ${tradingHorizontalSpace}px;

    .tradingInput-customLabel {
      width: 195px;
    }
  `}
`;

export const Main = styled(Wrapper)`
  ${() => css`
    width: 100%;
    margin: 20px 0 -5px 0;
  `}
`;

export const Amount = styled('div')`
  ${() => css`
    width: 100%;
    margin-bottom: -5px;
  `}
`;
