import { useMemo } from 'react';
import { clone } from 'mobx-state-tree';
import * as yup from 'yup';

import { analyzeHourRanges, getInstrumentTradingHours } from '@trader/utils';
import { useI18next } from '@trader/services';
import { TInstrumentEntity, useMst } from '@trader/store';

export const useOrderFormValidation = () => {
  const store = useMst();
  const { translate } = useI18next();

  const muliBands = store.pages.muliBands;
  const backTesting = muliBands.backTesting;

  const instrument = store.entities.instruments.get<TInstrumentEntity>(
    backTesting.getSymbol()
  );

  const instrumentTradingHours = useMemo(
    () => getInstrumentTradingHours(instrument.sessions),
    [instrument.sessions]
  );

  return yup.object().shape({
    closeTime: yup
      .date()
      .typeError('Invalid time')
      .when(['isCloseTime'], ([isCloseTime], schema) => {
        return schema.test(
          'closeTime',
          translate('MULI_BANDS.OUT_OF_TRADING_HOURS'),
          value => {
            if (!isCloseTime) {
              return true;
            }

            if (!value) {
              return false;
            }

            const analyzedTradingHours = analyzeHourRanges(
              clone(instrumentTradingHours),
              value
            );

            return analyzedTradingHours.hasMatchedWithCurrentDate;
          }
        );
      }),
  });
};
