import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { AssetDetails, InstrumentLayouts } from '@trader/containers';
import { Wrapper } from '@trader/components';

import * as Styled from './styled';

export const Dashboard: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Styled.Root>
      <InstrumentLayouts />
      {!isMobile && (
        <Wrapper>
          <AssetDetails />
        </Wrapper>
      )}
    </Styled.Root>
  );
};
