/* eslint-disable @typescript-eslint/no-explicit-any */
import { Instance, types } from 'mobx-state-tree';
import { MODAL_TYPES } from '@trader/constants';

const modalModel = types
  .model('modal', {
    id: types.optional(types.string, ''),
    isOpen: false,
    options: types.map(types.frozen()),
  })
  .actions(store => ({
    open(id: keyof typeof MODAL_TYPES, options?: any) {
      store.options.clear();
      store.isOpen = true;
      store.id = id;
      if (options) {
        // eslint-disable-next-line guard-for-in
        for (const key in options) {
          store.options.set(key, options[key]);
        }
      }
    },
    update(options: any) {
      store.options.clear();
      // eslint-disable-next-line guard-for-in
      for (const key in options) {
        store.options.set(key, options[key]);
      }
    },
    close() {
      store.isOpen = false;
      store.options.clear();
    },
  }));

export const modal = types.optional(modalModel, {});

export type TModalStore = Instance<typeof modalModel>;
