import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';

import { PlaceOrder, TradingInitializer } from '@trader/trading';
import { MoveableWrapper, Tabs } from '@trader/components';
import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';

import {
  Bands,
  HidingInfo,
  Order,
  DataBox,
  General,
  Settings,
} from './components';

import * as Styled from './styled';

export const RightMenu: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const dataBoxRef = React.useRef<HTMLDivElement>(null);

  const isDataBoxOpen = store.pages.muliBands.dataBox.isOpen;

  const renderConfig = () => (
    <Fragment>
      <Bands />
      <HidingInfo title={translate('MULI_BANDS.ORDER')} children={<Order />} />
      <HidingInfo
        title={translate('COMMON.LABELS.SETTINGS')}
        children={<Settings />}
      />
      <HidingInfo
        title={translate('MULI_BANDS.GENERAL')}
        children={<General />}
      />
    </Fragment>
  );

  if (!store.app.isBetaDesignEnabled()) {
    return (
      <Styled.Root>
        {renderConfig()}
        {isDataBoxOpen && <DataBox ref={dataBoxRef} />}
        {isDataBoxOpen && <MoveableWrapper elementRefToMove={dataBoxRef} />}
      </Styled.Root>
    );
  }

  const tabs = [
    {
      value: 'Config',
      label: translate('MULI_BANDS.BANDS'),
      children: renderConfig(),
    },
    {
      value: 'Order',
      label: translate('MULI_BANDS.BUY_SELL'),
      children: (
        <TradingInitializer modelType='createStrategyBand'>
          <PlaceOrder modelType='createStrategyBand' />
        </TradingInitializer>
      ),
    },
  ];

  return (
    <Styled.Root>
      <Tabs
        padding='0'
        hasBottomBorder={false}
        marginBottom='0'
        tabs={tabs}
        tabsPosition='top'
      />
      {isDataBoxOpen && <DataBox ref={dataBoxRef} />}
      {isDataBoxOpen && <MoveableWrapper elementRefToMove={dataBoxRef} />}
    </Styled.Root>
  );
});
