import { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { betaMaxSideBarWidth, LOCAL_STORAGE_KEYS } from '@trader/constants';
import { useMst } from '@trader/store';
import { localStorageService } from '@trader/services';
import { EDesignType } from '@trader/types';

/**
 * Initializes the beta design experience for users.
 */
export const useInitializeBetaDesign = () => {
  const store = useMst();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isAuth = store.auth.isAuth;

  useEffect(() => {
    // do not use beta design for phones
    if (isMobile) {
      store.app.runInAction(() => {
        store.app.designType = EDesignType.STABLE;
      });
    } else {
      if (!store.app.isBetaDesignEnabled()) {
        store.ui.sidebar.updateLeftBarWidth(betaMaxSideBarWidth);
        store.app.runInAction(() => {
          store.app.designType = EDesignType.BETA;
        });
        localStorageService.remove(LOCAL_STORAGE_KEYS.isBetaDesignInitialized);
      }
    }
  }, [isAuth, isMobile]);
};
