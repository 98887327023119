import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';
import {
  footerHeight,
  headerHeight,
  layoutChildrenRadius,
  layoutMargin,
} from '@trader/constants';

const layoutSides = 4; // top, bottom, left, right
const height = `${headerHeight + footerHeight + layoutMargin * layoutSides}px`;

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    width: 250px;
    min-width: 250px;
    height: calc(100vh - ${height});
    overflow: scroll;
    margin-left: ${layoutMargin}px;
    border-radius: ${layoutChildrenRadius}px;
    background: ${theme.palette.background.paper};

    > div > header {
      .MuiTabs-root {
        align-items: start;
      }

      .MuiTabs-flexContainer {
        width: 100%;
      }

      .tabButton {
        width: 50%;
        height: 32px;
        text-transform: uppercase;
        background-color: ${theme.palette.tab[100]};
        color: ${theme.palette.text.primary};
        font-weight: 400;
        font-size: 11px;
      }

      .Mui-selected {
        background-color: ${theme.palette.background.paper};
        color: ${theme.palette.text.primary};
        font-weight: 700;
        font-size: 11px;
      }
    }
  `}
`;

export const Container = styled(Wrapper)`
  ${({ theme }) => css`
    width: 261px;
    height: 645px;
    background: rgba(46, 48, 63, 0.7);
    position: absolute;
    right: 215px;
    bottom: 67px;
    z-index: ${theme.zIndex.modal + 1};
    cursor: move;
  `};
`;
