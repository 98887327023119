import { types } from 'mobx-state-tree';
import { modal } from './modals';
import { language } from './language';
import { sidebar } from './sidebar';
import { portfolio } from './portfolio';
import { tradingView } from './tradingView';

export const UiModel = types.model('UiModel', {
  modal,
  language,
  sidebar,
  portfolio,
  tradingView,
});

export const ui = types.optional(UiModel, {});
