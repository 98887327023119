import styled, { css } from 'styled-components';

import { Button, IconButton, Typography, Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';

export const Header = styled(Wrapper)<{
  $shouldApplyColumnDesign: boolean;
  $isMuliBands: boolean;
}>`
  ${({ theme, $shouldApplyColumnDesign, $isMuliBands }) => css`
    width: 100%;
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${$isMuliBands ? '5px 10px' : '5px'};
    border-bottom: 1px solid ${theme.palette.background.default};

    ${conditionApply(
      [$shouldApplyColumnDesign],
      css`
        height: 75px;
      `
    )}
  `};
`;

export const Info = styled(Wrapper)`
  ${() => css`
    width: 100%;
    flex-direction: row;
    align-items: center;
  `};
`;

export const InstrumentInfo = styled(Wrapper)`
  ${() => css`
    align-items: center;
  `};
`;

export const Symbol = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.heading.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    margin-top: 4px;
    margin-left: 8px;
    color: ${theme.palette.primary.dark};
  `};
`;

export const Difference = styled(Typography)<{
  $isAboveZero: boolean;
}>`
  ${({ theme, $isAboveZero }) => css`
    width: 150px;
    font-size: ${theme.typography.xl.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    margin-top: 4px !important;
    color: ${$isAboveZero
      ? theme.palette.purchaseButtons.green
      : theme.palette.purchaseButtons.red};
  `};
`;

export const ActionButton = styled(IconButton)`
  ${({ theme }) => css`
    color: ${theme.palette.common.black};
    padding: 0;

    svg {
      width: 20px;
      height: 20px;
    }
  `};
`;

export const InfoButton = styled(Typography)<{
  $isSelected: boolean;
  $withLeftSpacing?: boolean;
}>`
  ${({ theme, $isSelected, $withLeftSpacing }) => css`
    font-weight: ${theme.typography.fontWeightMedium};
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.08s ease-out;

    &:hover {
      background: ${theme.palette.gray[500]};
    }

    ${conditionApply(
      [$withLeftSpacing],
      css`
        margin-left: 4px !important;
      `
    )}

    ${conditionApply(
      [$isSelected],
      css`
        background-color: ${theme.palette.gray.light};
      `
    )}
  `};
`;

export const MenuItem = styled(Wrapper)<{ $isSelected: boolean }>`
  ${({ theme, $isSelected }) => css`
    cursor: pointer;
    padding: 8px;
    font-size: ${theme.typography.medium.fontSize};
    border-radius: 5px;
    user-select: none;

    &:hover {
      background: ${theme.palette.gray[500]};
    }

    ${conditionApply(
      [$isSelected],
      css`
        background-color: ${theme.palette.gray.light};
      `
    )}
  `};
`;

export const BackTestingToggle = styled(Button)`
  ${({ theme }) => css`
    margin-right: 12px;
    background: ${theme.palette.primary.dark};
    color: ${theme.palette.common.white};
    font-weight: ${theme.typography.fontWeightRegular};
    font-size: ${theme.typography.large.fontSize};
    padding: 2px 16px;

    :hover {
      background: ${theme.palette.primary.dark};
      color: ${theme.palette.common.white};
    }
  `};
`;

export const InstrumentSearch = styled(Wrapper)`
  ${({ theme }) => css`
    width: 300px;

    ${theme.breakpoints.down('md')} {
      width: 170px;
    }

    & .MuiOutlinedInput-root {
      padding: 0;
      height: 35px !important;
    }

    & .Mui-Autocomplete-search-field {
      background: ${theme.palette.black[100]};
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  `};
`;

export const ToggleLineAdaptersOnChart = styled(Wrapper)`
  ${() => css`
    padding: 0;
  `};
`;

export const TooltipTitle = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.small.fontSize};
  `};
`;

export const MobileHeader = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    padding: 8px;
    border-bottom: 1px solid ${theme.palette.background.default};
    flex-direction: column;

    .action_buttons {
      justify-content: space-between;

      button {
        width: 50%;
        padding: 18px 8px;

        div,
        span {
          font-size: ${theme.typography.xl.fontSize};
        }
      }
    }
  `};
`;
