import { Instance, types } from 'mobx-state-tree';
import { commonTrading } from './tradingModel';
import { TCommonOrderType } from '@trader/types';

const tradingModel = types
  .model('tradingModel', {
    createOrder: commonTrading,
    modifyOrder: commonTrading,
    createStrategyBand: commonTrading,
  })
  .views(state => ({
    getTrading: (type: TCommonOrderType) => state[type],
  }));

export const trading = types.optional(tradingModel, {});

export type TTradingInstance = Instance<typeof tradingModel>;
