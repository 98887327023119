import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { TInstrumentEntity, useMst } from '@trader/store';
import { formatByPipSize } from '@trader/utils';
import { useInstrument } from '@trader/hooks';
import { TCommonOrderType } from '@trader/types';

interface ITradingInitializer {
  children: React.ReactNode;
  modelType?: TCommonOrderType;
}

export const TradingInitializer: React.FC<ITradingInitializer> = observer(
  ({ children, modelType = 'createOrder' }) => {
    const store = useMst();

    const trading = store.trading.getTrading(modelType);

    const tradingSymbol = store.pages.trading.getInstrumentSymbolByLayout();
    const muliBandsSymbol = store.pages.muliBands.symbol;
    const symbol =
      modelType !== 'createStrategyBand' ? tradingSymbol : muliBandsSymbol;

    const entity = store.entities.instruments.get<TInstrumentEntity>(symbol);

    const handleUpdate = (askValue: number, bidValue: number) => {
      const ask = +formatByPipSize(askValue, entity?.pipSize);
      const bid = +formatByPipSize(bidValue, entity?.pipSize);

      store.entities.instruments.update(symbol, { ask, bid });
    };

    useEffect(() => {
      if (symbol) {
        trading.getInstrumentReferenceAsync.run({
          symbol,
          side: null,
        });
      }
    }, [symbol]);

    useInstrument(
      symbol,
      entity?.spreadDiff,
      entity?.spreadDiffBalance,
      entity?.pipSize,
      handleUpdate
    );

    return children;
  }
);
