import { IDataFreeParams } from './types';
import { indexDBName } from './constants';

export const get = <TResponse>({
  key,
  version,
  storeName,
}: IDataFreeParams): Promise<TResponse | undefined> =>
  new Promise(resolve => {
    const request = indexedDB.open(indexDBName, version);

    request.onsuccess = () => {
      const tx = request.result.transaction(storeName, 'readonly');
      const store = tx.objectStore(storeName);
      const res = !key ? store.getAll() : store.get(key);

      res.onsuccess = () => {
        resolve(res.result as TResponse);
      };
    };
  });
