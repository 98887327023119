import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { TInstrumentEntity, useMst } from '@trader/store';
import { getTradingCentralValues } from '@trader/utils';
import { useI18next } from '@trader/services';

import { Tooltip } from '../tooltip';

import * as Styled from './styled';

export const TradingCentralInfo: React.FC = observer(() => {
  const store = useMst();
  const [isOpenView, setIsOpenView] = useState(true);
  const { translate } = useI18next();

  const layoutType = store.pages.trading.layout.layoutType;
  const isDisplayIndicators = store.user.settings.isDisplayedByUser;

  const symbol = store.pages.trading.getInstrumentSymbolByLayout();

  const tradingCentral =
    store.entities.instruments.get<TInstrumentEntity>(symbol)?.tradingCentral;

  const tradingCentralValues = useMemo(() => {
    return getTradingCentralValues(tradingCentral);
  }, [symbol, tradingCentral]);

  if (
    !isDisplayIndicators ||
    !tradingCentralValues?.length ||
    layoutType !== 'single'
  ) {
    return null;
  }

  if (!isOpenView) {
    return (
      <Styled.Root $isOpenView={isOpenView}>
        <Styled.Title $isOpenView={isOpenView}>
          {translate('CHART.TRADING_CENTRAL_PREFERENCE_INTRADAY_RISE')}
        </Styled.Title>
        <Styled.ToggleButton
          $isOpenView={isOpenView}
          variant='text'
          onClick={() => setIsOpenView(!isOpenView)}
          iconType='minimize'
        >
          {translate('COMMON.LABELS.MAXIMIZE')}
        </Styled.ToggleButton>
      </Styled.Root>
    );
  }

  return (
    <Styled.Root $isOpenView={isOpenView}>
      <Styled.ToggleButton
        $isOpenView={isOpenView}
        variant='text'
        onClick={() => setIsOpenView(!isOpenView)}
        iconType='maximize'
      >
        {translate('COMMON.LABELS.MINIMIZE')}
      </Styled.ToggleButton>
      <Styled.ContentContainer>
        <Styled.Title $isOpenView={isOpenView}>
          {translate('CHART.TRADING_CENTRAL_PREFERENCE_INTRADAY_RISE')}
        </Styled.Title>
        {tradingCentralValues.map(i => (
          <Tooltip key={i} title={i}>
            <Styled.Paragraph>{i}</Styled.Paragraph>
          </Tooltip>
        ))}
      </Styled.ContentContainer>
      <Styled.ContentContainer>
        <Styled.Title $isOpenView={isOpenView}>
          {translate('COMMON.LABELS.LEGEND')}
        </Styled.Title>
        <Styled.Paragraph>
          <span className='resistance'>R1, R2, R3</span>{' '}
          {translate('CHART.LINES_REPRESENT_RESISTANCE')}
        </Styled.Paragraph>
        <Styled.Paragraph>
          <span className='support'>S1, S2, S3</span>{' '}
          {translate('CHART.LINES_REPRESENT_SUPPORT_LEVELS')}
        </Styled.Paragraph>
        <Tooltip
          title={translate('CHART.PIVOT_LINE_IS_THE_PIVOT_POINT_TOOLTIP')}
        >
          <Styled.Paragraph>
            <span className='pivot'>{translate('COMMON.LABELS.PIVOT')}</span>
            {translate('CHART.PIVOT_LINE_IS_THE_PIVOT_POINT')}
          </Styled.Paragraph>
        </Tooltip>
      </Styled.ContentContainer>
    </Styled.Root>
  );
});
