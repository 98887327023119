import { isNumber } from 'lodash';
import {
  isGrowTransfer as isGrowTransferCore,
  getSign as getSignCore,
  formatNumber as formatNumberCore,
  formatPercents as formatPercentsCore,
  TransferTypes,
} from '@tchtrade/trade-core';

import { getPipSizeNumber } from '../trading';

export const isGrowTransfer = (transferType: string) =>
  isGrowTransferCore(TransferTypes[transferType]);

export const getSign = (
  amount: number,
  withSign?: boolean,
  transferType?: string
) => getSignCore(amount, withSign, transferType && TransferTypes[transferType]);

export const formatNumber = formatNumberCore;

export const formatPercents = formatPercentsCore;

export const formatMoney = (
  amount: number | undefined,
  options: {
    currencySymbol: string;
    hasSign?: boolean;
    pipSize?: number;
    hasDecimals?: boolean;
    fallBack?: string;
    transferType?: string;
  }
) => {
  const {
    currencySymbol,
    hasSign,
    pipSize = 2,
    fallBack = '--',
    hasDecimals = true,
    transferType,
  } = options;

  if (!isNumber(amount)) {
    return `${currencySymbol}${fallBack}`;
  }

  return `${getSign(
    amount!,
    hasSign,
    transferType
  )}${currencySymbol}${formatNumber(amount!, {
    maximumFractionDigits: getPipSizeNumber(pipSize),
    minimumFractionDigits: hasDecimals ? getPipSizeNumber(pipSize) : undefined,
  })}`;
};
