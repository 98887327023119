import { getIconBaseProps } from './utils';

export const muliBands = {
  ...getIconBaseProps('muliBands'),
  width: '19px',
  height: '19px',
  viewBox: '0 0 19 19',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <path
      d='M16.3 11.2V18M9.5 8.36667V18M2.7 14.6V18M3.83333 8.23287C3.53257 7.96368 3.1354 7.8 2.7 7.8C1.76112 7.8 1 8.56112 1 9.5C1 10.4389 1.76112 11.2 2.7 11.2C3.63888 11.2 4.4 10.4389 4.4 9.5C4.4 8.99652 4.18112 8.54415 3.83333 8.23287ZM3.83333 8.23287L8.23287 3.83333M8.23287 3.83333C8.54415 4.18112 8.99652 4.4 9.5 4.4C10.2402 4.4 10.8699 3.92694 11.1033 3.26667M8.23287 3.83333C7.96368 3.53257 7.8 3.1354 7.8 2.7C7.8 1.76112 8.56112 1 9.5 1C10.4389 1 11.2 1.76112 11.2 2.7C11.2 2.89869 11.1659 3.08943 11.1033 3.26667M11.1033 3.26667L14.6555 4.53417M14.6555 4.53417C14.6193 4.67226 14.6 4.81721 14.6 4.96667C14.6 5.90555 15.3611 6.66667 16.3 6.66667C17.2389 6.66667 18 5.90555 18 4.96667C18 4.02778 17.2389 3.26667 16.3 3.26667C15.5106 3.26667 14.8468 3.80476 14.6555 4.53417Z'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  ),
};
