import { FC } from 'react';
import { Stack, useTheme, useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { defaultIcon } from '@trader/constants';
import { getInstrumentDifference } from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';
import { useResponsiveDesign } from '@trader/hooks';
import { EChartLayouts } from '@trader/types';
import { ActionButtons, Tooltip, Wrapper } from '@trader/components';

import { IInstrumentDetailsHeader } from '../index';
import { RightSideActionBar } from './components/rightSideActionBar';
import { MarketStatusIcon } from './components/marketStatusIcon';
import { FavouriteIconButton } from './components/favoriteIconButton';
import { PriceAlertIconButton } from './components/priceAlertIconButton';
import * as Styled from './styled';

export const BetaDesign: FC<IInstrumentDetailsHeader> = observer(props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const store = useMst();
  const symbol = store.pages.trading.getInstrumentSymbolByLayout();
  const storeInstrument =
    store.entities.instruments.get<TInstrumentEntity>(symbol);
  const instrument = props.instrument || {
    ...storeInstrument,
    layoutNumber: EChartLayouts.FirstLayout,
  };

  if (!instrument?.symbol) {
    return null;
  }

  return isMobile ? (
    <MobileInstrumentDetailsHeader {...props} instrument={instrument} />
  ) : (
    <DesktopInstrumentDetailsHeader {...props} instrument={instrument} />
  );
});

const DesktopInstrumentDetailsHeader: FC<IInstrumentDetailsHeader> = observer(
  ({ instrument, isMuliBandsStrategy, onChangeSymbol, searchCategory }) => {
    const {
      instrumentDetailsHeader: {
        shouldApplyColumnDesign,
        shouldMinimizeRightSideActionBar,
      },
    } = useResponsiveDesign();

    if (!instrument?.symbol) {
      return null;
    }

    const { isGrow, openCloseDifferenceInPercent, openCloseDifference } =
      getInstrumentDifference(
        instrument.ask,
        instrument.close,
        instrument.pipSize
      );

    return (
      <Styled.Header
        $shouldApplyColumnDesign={shouldApplyColumnDesign}
        $isMuliBands={!!isMuliBandsStrategy}
      >
        <Wrapper>
          <Stack
            direction={shouldApplyColumnDesign ? 'column' : 'row'}
            spacing='8px'
            alignItems={shouldApplyColumnDesign ? undefined : 'center'}
          >
            <Wrapper alignItems='flex-start'>
              <img
                src={instrument.iconUrl || defaultIcon}
                alt='instrument icon'
                width='30px'
                height='30px'
              />
              <Tooltip title={instrument.description}>
                <Styled.Symbol>{instrument.symbol}</Styled.Symbol>
              </Tooltip>
            </Wrapper>
            <Styled.Difference $isAboveZero={isGrow}>
              {openCloseDifference} ({openCloseDifferenceInPercent}%)
            </Styled.Difference>
          </Stack>
          <ActionButtons
            direction={shouldApplyColumnDesign ? 'column' : 'row'}
            symbol={instrument.symbol}
            ask={instrument.ask}
            bid={instrument.bid}
            pipSize={instrument.pipSize}
          />
        </Wrapper>
        <RightSideActionBar
          instrument={instrument}
          isMinimized={shouldMinimizeRightSideActionBar}
          isMuliBandsStrategy={isMuliBandsStrategy}
          isColumnDesign={shouldApplyColumnDesign}
          searchCategory={searchCategory}
          onChangeSymbol={onChangeSymbol}
        />
      </Styled.Header>
    );
  }
);

const MobileInstrumentDetailsHeader: FC<IInstrumentDetailsHeader> = observer(
  ({ instrument }) => {
    if (!instrument?.symbol) {
      return null;
    }

    const { isGrow, openCloseDifferenceInPercent, openCloseDifference } =
      getInstrumentDifference(
        instrument.ask,
        instrument.close,
        instrument.pipSize
      );

    return (
      <Styled.MobileHeader>
        <Wrapper width='100%' justifyContent='space-between' marginBottom='8px'>
          <Wrapper alignItems='center'>
            <img
              src={instrument.iconUrl || defaultIcon}
              alt='instrument icon'
              width='30px'
              height='30px'
            />
            <Styled.Symbol marginRight='8px'>{instrument.symbol}</Styled.Symbol>
            <Styled.Difference $isAboveZero={isGrow}>
              {openCloseDifference} ({openCloseDifferenceInPercent}%)
            </Styled.Difference>
          </Wrapper>
          <Stack direction='row' spacing='12px'>
            <MarketStatusIcon
              isUnavailable={instrument.tradingAvailability.isUnavailable}
              openIn={instrument.tradingAvailability.openIn}
            />
            <FavouriteIconButton instrument={instrument} />
            <PriceAlertIconButton symbol={instrument.symbol} />
          </Stack>
        </Wrapper>
        <ActionButtons
          symbol={instrument.symbol}
          ask={instrument.ask}
          bid={instrument.bid}
          pipSize={instrument.pipSize}
        />
      </Styled.MobileHeader>
    );
  }
);
